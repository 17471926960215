import React from 'react';
import Tree from 'react-d3-tree';

// This is a simplified example of an org chart with a depth of 2.
// Note how deeper levels are defined recursively via the `children` property.
const orgChart = {
  name: 'CEO',
  children: [
    {
      name: 'Manager',
      attributes: {
        department: 'Production',
      },
      children: [
        {
          name: 'Foreman',
          attributes: {
            department: 'Fabrication',
          },
          children: [
            {
              name: 'Worker',
            },
          ],
        },
        {
          name: 'Foreman',
          attributes: {
            department: 'Assembly',
          },
          children: [
            {
              name: 'Worker',
            },
          ],
        },
      ],
    },
  ],
};

export default function OrgChartTree() {
  return (
    // `<Tree />` will fill width/height of its container; in this case `#treeWrapper`.
    <div id="treeWrapper" style={{ width: '50em', height: '20em' }}>
      <ul>
        <li>

          <a class="fatherTOP" href="javascript:void(0);">

            <div class="member-view-box">
              <div class="member-image">
                <img src="images/bff/person.png" alt="Member" />
                <div class="member-details">
                  <h3>iswap636 - [DIUPJMAY]</h3>
                </div>
              </div>

            </div>

          </a>


          <ul class="active">
            <li>
              <span class="leftRight left_branch">Left</span>								                        <a data-level="1" class="highlight" id="getChile_3026" href="javascript:void(0);">
                <div class="member-view-box">
                  <div class="member-image">
                    <img src="images/bff/person.png" alt="Member" />
                    <div class="member-details">
                      <h3>Mei Mei- [NYE1VLKR]</h3>
                    </div>
                  </div>
                  <span class="volume highlight">Lending <strong>100 BFF</strong></span>

                </div>
                <span class="level">Lv: 1</span>
                <div class="action-child">
                  <span class="plus hidden" onclick="getChild('#getChile_', 3026, 3025)">+</span>
                  <span class="minus" onclick="rmChild('#getChile_', 3026, 3025)">-</span>
                </div>
              </a>
              <ul class="active"><li>

                <a data-level="2" class="highlight" id="getChile_3036" href="javascript:void(0);">
                  <div class="member-view-box">
                    <div class="member-image">
                      <img src="images/bff/person.png" alt="Member" />
                      <div class="member-details">
                        <h3>Phan Diep Hong Ngan- [TO16YMJD]</h3>
                      </div>
                      <span class="volume highlight">Total amount deposited <strong>10,000 BFF</strong></span>

                    </div>
                  </div>
                  <span class="level">Lv: 2</span>
                  <div class="action-child">
                    <span class="plus" onclick="getChild('#getChile_', 3036, 3025)">+</span>
                    <span class="minus hidden" onclick="rmChild('#getChile_', 3036, 3025)">-</span>
                  </div>
                </a>
              </li></ul></li>
            <li>
              <span class="leftRight right_branch">Right</span>								                        <a data-level="1" class="" id="getChile_3031" href="javascript:void(0);">
                <div class="member-view-box">
                  <div class="member-image">
                    <img src="images/bff/person.png" alt="Member" />
                    <div class="member-details">
                      <h3>Phan Diep Hong Ngan- [CQY1SDVR]</h3>
                    </div>
                  </div>
                  <span class="volume ">No transactions yet</span>
                </div>
                <span class="level">Lv: 1</span>
                <div class="action-child">
                  <span class="plus hidden" onclick="getChild('#getChile_', 3031, 3025)">+</span>
                  <span class="minus" onclick="rmChild('#getChile_', 3031, 3025)">-</span>
                </div>
              </a>
              <ul class="active"><li>

                <a data-level="2" class="highlight" id="getChile_3187" href="javascript:void(0);">
                  <div class="member-view-box">
                    <div class="member-image">
                      <img src="images/bff/person.png" alt="Member" />
                      <div class="member-details">
                        <h3>LiemNguyen- [7S309BHM]</h3>
                      </div>
                      <span class="volume highlight">Total amount deposited <strong>1,000 BFF</strong></span>

                    </div>
                  </div>
                  <span class="level">Lv: 2</span>
                  <div class="action-child">
                    <span class="plus" onclick="getChild('#getChile_', 3187, 3025)">+</span>
                    <span class="minus hidden" onclick="rmChild('#getChile_', 3187, 3025)">-</span>
                  </div>
                </a>
              </li></ul></li>
          </ul>


        </li>
      </ul>
    </div>
  );
}