import HeaderV2 from "components/Header/HeaderV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import Layout from "components/layout/Layout";
import React from "react";

function Referral() {
  return (
    <Layout>
      <div
        style={{ backgroundColor: "#0D102D" }}
        className="body customers_page flex-grow-1 px-3"
      >
        <div className="body customers_page flex-grow-1 px-3">
          <div className="container-lg ibgate-page">
            <h1>Referral Links</h1>
            <div className="row linkref">
              <div className="col-md-4">
                <div className="box">
                  <h3>Direct referral link</h3>
                  <span
                    className="copytext"
                    onclick="copyText('#reffLink1', 'Copy success')"
                  >
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAJFBMVEXjVyhHcEzjVyjjVyjjVyjjVyjjVyjjVyjjVyjjVyjjVyjjVyhdC0/bAAAAC3RSTlNYADxEZh7evIBBioNWQJsAAABhSURBVBjTYxAUFJ1sDAamggyCgty7oWAhkJPGAAHsW4GcFEEIENjEIBgJ0TEVxJGA6WgEcqJLXMDAfSuQo80I0zHIOG5bkThpjEgcQUGG6AKYp4EcKbjngBzBYFhACQoCABWcQPZ6tyoGAAAAAElFTkSuQmCC"
                      alt=""
                      className="fr"
                    />
                  </span>
                  <p id="reffLink1">
                    https://bfflendinglewlew.info/singup?ref=DIUPJMAY
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Referral;
