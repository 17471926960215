import React, { useState, useEffect } from 'react';

const Notification = ({ status, msg }) => {
    const [visible, setVisible] = useState(true);

    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         setVisible(false);
    //     }, duration);

    //     return () => clearTimeout(timeout);
    // }, [duration]);

    // const handleClose = () => {
    //     setVisible(false);
    // };

    return (
        <>
            {visible && (
                <div className={`ant-message ant-message-appear ant-message-${status}`}>
                    <div className={`ant-message-notice ant-message-${status}`}>
                        <div className="ant-message-notice-content">
                            <div className={`ant-message-custom-content ant-message-${status}`} style={{display: 'flex', alignItems: 'center'}}>
                                <span role="img" aria-label={status} className={`anticon anticon-${status}`}>
                                    <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon={status === 'success' ? 'check-circle' : 'close-circle'}
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path d={status === 'success' ? "M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z" : "M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm331.7 705.6l-55.3 55.3a8.03 8.03 0 01-11.3 0L512 567.3 322.9 756.6a8.03 8.03 0 01-11.3 0l-55.3-55.3a8.03 8.03 0 010-11.3L456.7 512 267.4 322.9a8.03 8.03 0 010-11.3l55.3-55.3a8.03 8.03 0 0111.3 0L512 456.7l189.1-189.3a8.03 8.03 0 0111.3 0l55.3 55.3a8.03 8.03 0 010 11.3L567.3 512l189.3 189.1a8.03 8.03 0 010 11.3z"}></path>
                                    </svg>
                                </span>
                                <span>{msg}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Notification;