import HeaderV2 from "components/Header/HeaderV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import Layout from "components/layout/Layout";
import React from "react";

function TransferMoneyHistory() {
  return (
    <Layout>
      <div
        style={{ backgroundColor: "#0D102D" }}
        className="body customers_page flex-grow-1 px-3"
      >
        <div className="body customers_page flex-grow-1 px-3">
          <div className="container-lg ibgate-page">
            <h1>Internal money transfer history</h1>
            <div className="header_page wChoose">
              <a
                href="https://bfflendinglewlew.info/gate/control/transfer-money-history?type=sent"
                className="active"
              >
                Money transfer history
              </a>

              <a
                href="https://bfflendinglewlew.info/gate/control/transfer-money-history?type=receive"
                className=""
              >
                History of receiving money
              </a>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-4">
                  <div className="table-responsive listSubAccounts">
                    <table
                      style={{
                        backgroundColor: "#0D102D",
                      }}
                      className="table border mb-0 "
                    >
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle">
                          <th className="th_tb_else text-center">
                            Date created
                          </th>
                          <th className="th_tb_else text-center">Sender</th>
                          <th className="th_tb_else text-center">Receiver</th>
                          <th className="th_tb_else text-center">
                            Amount of money
                          </th>
                        </tr>
                      </thead>
                      <tbody className="getAllSubUser"></tbody>
                    </table>
                  </div>
                </div>
                <div className="nav-pa"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TransferMoneyHistory;
