import { getWeb3Provider } from "service";
import Web3 from "web3";

const get_address = () => {
  return async function (dispatch) {
    // const web3 = new Web3(Web3.givenProvider);
    // console.log('start');
    setInterval(async () => {
      // console.log('111111');
      if (window.localStorage.getItem("isLogin") == 'false') {
        dispatch({
          type: "GET_ADDRESS",
          payload: null,
        });
        return
      }
      let web3;
      if (Web3.givenProvider && window.localStorage.getItem("providerType") != 'wallet') {
        web3 = new Web3(Web3.givenProvider);
      } else {
        web3 = await getWeb3Provider(Web3.givenProvider);
      }

      // console.log(web3);
      web3.eth.getAccounts().then((accounts) => {
        // console.log(accounts);
        if (accounts.length) {
          dispatch({
            type: "GET_ADDRESS",
            payload: accounts[0],
          });
        } else {
          dispatch({
            type: "GET_ADDRESS",
            payload: null,
          });
        }
      });
    }, 1000);
  };
};

export { get_address };
