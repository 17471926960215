import HeaderV2 from "components/Header/HeaderV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import Layout from "components/layout/Layout";
import LoadingButton from "components/lib/loading";
import React, { useEffect, useRef, useState } from "react";
import { formatNumber } from "service";
import { getInfoUserss, tranfer } from "service/indexNew";

function TransferMoney() {
  const [dataUser, setDataUser] = useState({});
  const [amount, setAmount] = useState(0);
  const [toAddress, setToAddress] = useState('');
  const timerRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const countdown = () => {
      getData()
    };
    timerRef.current = setInterval(countdown, 1000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);
  const getData = async () => {
    let data = await getInfoUserss();
    setDataUser(data)
  }

  const _tranfer = async () => {
    console.log('runnn');
    setLoading(true)
    let res = await tranfer(amount, toAddress);
    console.log(res);
    console.log(res.code);
    console.log(res.code != 200);
    if (res.code == 200) {
      // if (res?.message) {
      alert('success')
      // } else {
      //   alert('error', "fail")
      // }
    } else {
      alert(res?.message)
    }
    setLoading(false);
  }


  return (
    <Layout>
      <div
        style={{ backgroundColor: "#0D102D" }}
        className="body customers_page flex-grow-1 px-3"
      >
        <div className="body customers_page flex-grow-1 px-3">
          <div className="container-lg ibgate-page">
            <div
              id="deposit"
              className="deposit-page deposit-lending deposit-page-crypto boxLending"
            >
              <div className="form_bank">
                <h2 className="getTitleBank">Internal money transfer [BFF]</h2>
                <p
                  style={{
                    color: "#FFF",
                  }}
                >
                  Available balances: {formatNumber(dataUser?.balanceBff)} BFF
                </p>

                <form
                  action="#"
                >
                  <div className="row form_content">
                    <div className="col-md-3">
                      <div className="form_group">
                        <label for="">
                          <i className="fas fa-star-of-life"></i>
                          <span className="getTitleBank">
                            {" "}
                            Enter the amount (BFF)
                          </span>
                        </label>
                        <input
                          name="money"
                          data-type="money"
                          required
                          className="form-control form-control-lg"
                          min="10"
                          data-currency="BFF"
                          data-price="1"
                          type={"number"}
                          value={amount}
                          onChange={(e) => { setAmount(e.target.value) }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form_group mb-0">
                        <label for="">
                          Enter address
                        </label>
                        <input
                          name="toperson"
                          className="form-control form-control-lg"
                          type="text"
                          value={toAddress}
                          onChange={(e) => { setToAddress(e.target.value) }}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      {/* <button type="submit">Send</button> */}
                      {
                        loading ?
                          <LoadingButton type="button" className="ant-btn ant-btn-primary" />
                          :
                          <a className="slslslsls" onClick={() => { _tranfer() }}>Send</a>
                      }
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TransferMoney;
