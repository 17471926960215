import React, { Component } from "react";
import * as LINK from "./../../constants/Links";

import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Route, Switch, Link, Redirect } from "react-router-dom";

import * as Actions from "./../../actions/index";

const Index = () =>
{
  return (
    <div class="mt-5 p-6 container">
      <div class="columns">
        <div class="column is-flex-grow-5">
          <div class="ant-card ant-card-bordered">
            <div class="ant-card-body">
              <div class="flex items-center"><input type="text" class="input mr-2" placeholder="Search by token address ..." /></div>
              <ul class="PinkLockTokenPage_filter__1hvea">
                <li class="PinkLockTokenPage_active__3lof3">All</li>
                <li class="">My lock</li>
              </ul>
              <div class="ant-spin-nested-loading">
                <div class="ant-spin-container">
                  <ul class="ant-list-items">
                    <li class="ant-list-item">
                      <div class="PinkLockList_tvl__2g8Av"><strong>
                        <p>Token</p>
                      </strong></div>
                      <div class="PinkLockList_tvl__2g8Av"><strong>Amount</strong>
                      </div>
                      <div style={{ opacity: "0" }}>View</div>
                    </li>
                  </ul>
                  <div class="ant-list ant-list-split">
                    <div class="ant-spin-nested-loading">
                      <div class="ant-spin-container">
                        <ul class="ant-list-items">
                          <li class="ant-list-item">
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="flex items-center">
                                <div class="TokenIcon_root__1Ga9R TokenIcon_medium__2aNEt" style={{ backgroundImage: "url(&quot;/static/media/unknown-token.5e4a517e.png&quot;)" }}>
                                </div>
                                <div class="flex-1 pl-4">
                                  <div class="PinkLockListItem_label__sjxqL">
                                    SensoDEX</div>
                                  <div class="PinkLockListItem_symbol__1jVj2">
                                    SenDEX</div>
                                </div>
                              </div>
                            </div>
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="PinkLockListItem_value__2cW5b">
                                9,000,000 SenDEX</div>
                            </div><a href="/pinklock/detail/0xCA5bFeEEf3265FF6269Cd6E863aC29cb9976B300?chain=BSC">View</a>
                          </li>
                          <li class="ant-list-item">
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="flex items-center">
                                <div class="TokenIcon_root__1Ga9R TokenIcon_medium__2aNEt" style={{ backgroundImage: "url(&quot;https://photos.pinksale.finance/file/pinksale-logo-upload/1668627508683-09dd8c2662b96ce14928333f055c5580.png&quot;)" }}>
                                </div>
                                <div class="flex-1 pl-4">
                                  <div class="PinkLockListItem_label__sjxqL">
                                    QatarGrow</div>
                                  <div class="PinkLockListItem_symbol__1jVj2">
                                    QatarGrow</div>
                                </div>
                              </div>
                            </div>
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="PinkLockListItem_value__2cW5b">
                                150,000,000 QatarGrow</div>
                            </div><a href="/pinklock/detail/0x238f5cC8bd082895d1F832cef967e7bb7Ba4F992?chain=BSC">View</a>
                          </li>
                          <li class="ant-list-item">
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="flex items-center">
                                <div class="TokenIcon_root__1Ga9R TokenIcon_medium__2aNEt" style={{ backgroundImage: "url(&quot;/static/media/unknown-token.5e4a517e.png&quot;)" }}>
                                </div>
                                <div class="flex-1 pl-4">
                                  <div class="PinkLockListItem_label__sjxqL">
                                    AIGO</div>
                                  <div class="PinkLockListItem_symbol__1jVj2">
                                    AIGO</div>
                                </div>
                              </div>
                            </div>
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="PinkLockListItem_value__2cW5b">
                                20,000,000 AIGO</div>
                            </div><a href="/pinklock/detail/0xe63eE9e00C9461F062D0C993746F1918969a8888?chain=BSC">View</a>
                          </li>
                          <li class="ant-list-item">
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="flex items-center">
                                <div class="TokenIcon_root__1Ga9R TokenIcon_medium__2aNEt" style={{ backgroundImage: "url(&quot;/static/media/unknown-token.5e4a517e.png&quot;)" }}>
                                </div>
                                <div class="flex-1 pl-4">
                                  <div class="PinkLockListItem_label__sjxqL">
                                    SHJ</div>
                                  <div class="PinkLockListItem_symbol__1jVj2">
                                    SHJ</div>
                                </div>
                              </div>
                            </div>
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="PinkLockListItem_value__2cW5b">1.28 SHJ </div>
                            </div><a href="/pinklock/detail/0x829E7183f68aC30526D27Bb70EC8dE51DcCD6161?chain=BSC">View</a>
                          </li>
                          <li class="ant-list-item">
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="flex items-center">
                                <div class="TokenIcon_root__1Ga9R TokenIcon_medium__2aNEt" style={{ backgroundImage: "url(&quot;/static/media/unknown-token.5e4a517e.png&quot;)" }}>
                                </div>
                                <div class="flex-1 pl-4">
                                  <div class="PinkLockListItem_label__sjxqL">
                                    Ftest1</div>
                                  <div class="PinkLockListItem_symbol__1jVj2">
                                    Ftest1</div>
                                </div>
                              </div>
                            </div>
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="PinkLockListItem_value__2cW5b">
                                250,000,000 Ftest1</div>
                            </div><a href="/pinklock/detail/0x7B56D1B857754149d84346080e9eD642F3109ca3?chain=BSC">View</a>
                          </li>
                          <li class="ant-list-item">
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="flex items-center">
                                <div class="TokenIcon_root__1Ga9R TokenIcon_medium__2aNEt" style={{ backgroundImage: "url(&quot;https://i.ibb.co/2yXFJLp/s.png&quot;)" }}>
                                </div>
                                <div class="flex-1 pl-4">
                                  <div class="PinkLockListItem_label__sjxqL">
                                    Sport Chain</div>
                                  <div class="PinkLockListItem_symbol__1jVj2">
                                    SportC</div>
                                </div>
                              </div>
                            </div>
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="PinkLockListItem_value__2cW5b">
                                399,679,000,000 SportC</div>
                            </div><a href="/pinklock/detail/0x528326D4DaC427ECBeEa1A41C5429d8CB12aBAed?chain=BSC">View</a>
                          </li>
                          <li class="ant-list-item">
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="flex items-center">
                                <div class="TokenIcon_root__1Ga9R TokenIcon_medium__2aNEt" style={{ backgroundImage: "url(&quot;https://pbs.twimg.com/profile_images/1592402324622053381/oJyXFRx3_400x400.jpg&quot;)" }}>
                                </div>
                                <div class="flex-1 pl-4">
                                  <div class="PinkLockListItem_label__sjxqL">
                                    SoccerN</div>
                                  <div class="PinkLockListItem_symbol__1jVj2">
                                    SON</div>
                                </div>
                              </div>
                            </div>
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="PinkLockListItem_value__2cW5b">
                                68,000,000 SON</div>
                            </div><a href="/pinklock/detail/0xF0DCA6AaD98f49CFd9078723AA232b23c29F8df5?chain=BSC">View</a>
                          </li>
                          <li class="ant-list-item">
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="flex items-center">
                                <div class="TokenIcon_root__1Ga9R TokenIcon_medium__2aNEt" style={{ backgroundImage: "url(&quot;/static/media/unknown-token.5e4a517e.png&quot;)" }}>
                                </div>
                                <div class="flex-1 pl-4">
                                  <div class="PinkLockListItem_label__sjxqL">
                                    Winner</div>
                                  <div class="PinkLockListItem_symbol__1jVj2">
                                    Winner</div>
                                </div>
                              </div>
                            </div>
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="PinkLockListItem_value__2cW5b">
                                18,000,000 Winner</div>
                            </div><a href="/pinklock/detail/0xE75D5af7f388925a5c0cca44844612b1683c9473?chain=BSC">View</a>
                          </li>
                          <li class="ant-list-item">
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="flex items-center">
                                <div class="TokenIcon_root__1Ga9R TokenIcon_medium__2aNEt" style={{ backgroundImage: "url(&quot;/static/media/unknown-token.5e4a517e.png&quot;)" }}>
                                </div>
                                <div class="flex-1 pl-4">
                                  <div class="PinkLockListItem_label__sjxqL">
                                    AIGO</div>
                                  <div class="PinkLockListItem_symbol__1jVj2">
                                    AIGO</div>
                                </div>
                              </div>
                            </div>
                            <div class="PinkLockListItem_tvl__3xbJ6">
                              <div class="PinkLockListItem_value__2cW5b">
                                25,000,000 AIGO</div>
                            </div><a href="/pinklock/detail/0xDa3a1324523E23676924084f5F58Ee1b33146bb3?chain=BSC">View</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="has-text-centered mt-4">
                <ul class="ant-pagination" unselectable="unselectable">
                  <li title="Previous Page" class="ant-pagination-prev ant-pagination-disabled" aria-disabled="true"><button class="ant-pagination-item-link" type="button" tabIndex="-1" disabled=""><span role="img"
                    aria-label="left" class="anticon anticon-left"><svg
                      viewBox="64 64 896 896" focusable="false"
                      data-icon="left" width="1em" height="1em"
                      fill="currentColor" aria-hidden="true">
                      <path
                        d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z">
                      </path>
                    </svg></span></button></li>
                  <li title="1" class="ant-pagination-item ant-pagination-item-1 ant-pagination-item-active" tabIndex="0"><a rel="nofollow">1</a></li>
                  <li title="2" class="ant-pagination-item ant-pagination-item-2" tabIndex="0"><a rel="nofollow">2</a></li>
                  <li title="3" class="ant-pagination-item ant-pagination-item-3" tabIndex="0"><a rel="nofollow">3</a></li>
                  <li title="4" class="ant-pagination-item ant-pagination-item-4" tabIndex="0"><a rel="nofollow">4</a></li>
                  <li title="5" class="ant-pagination-item ant-pagination-item-5 ant-pagination-item-before-jump-next" tabIndex="0"><a rel="nofollow">5</a></li>
                  <li title="Next 5 Pages" tabIndex="0" class="ant-pagination-jump-next ant-pagination-jump-next-custom-icon">
                    <a class="ant-pagination-item-link">
                      <div class="ant-pagination-item-container"><span role="img" aria-label="double-right" class="anticon anticon-double-right ant-pagination-item-link-icon"><svg
                        viewBox="64 64 896 896" focusable="false"
                        data-icon="double-right" width="1em" height="1em"
                        fill="currentColor" aria-hidden="true">
                        <path
                          d="M533.2 492.3L277.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H188c-6.7 0-10.4 7.7-6.3 12.9L447.1 512 181.7 851.1A7.98 7.98 0 00188 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5zm304 0L581.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H492c-6.7 0-10.4 7.7-6.3 12.9L751.1 512 485.7 851.1A7.98 7.98 0 00492 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5z">
                        </path>
                      </svg></span><span class="ant-pagination-item-ellipsis">•••</span></div>
                    </a>
                  </li>
                  <li title="451" class="ant-pagination-item ant-pagination-item-451" tabIndex="0"><a rel="nofollow">451</a></li>
                  <li title="Next Page" tabIndex="0" class="ant-pagination-next" aria-disabled="false"><button class="ant-pagination-item-link" type="button" tabIndex="-1"><span role="img" aria-label="right"
                    class="anticon anticon-right"><svg viewBox="64 64 896 896"
                      focusable="false" data-icon="right" width="1em"
                      height="1em" fill="currentColor" aria-hidden="true">
                      <path
                        d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z">
                      </path>
                    </svg></span></button></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) =>
{
  return {

  };
};
const mapDispatchToProps = (dispatch, props) =>
{
  return {
    // GetConvertion: (idpage) =>
    // {
    //   dispatch(Actions.GetConvertionRequest(idpage));
    // },
    // Getprovince: () =>
    // {
    //   dispatch(Actions.GetprovinceRequest());
    // },
    // GetComment: (idpage) =>
    // {
    //   dispatch(Actions.GetCommentRequest(idpage));
    // },
    // GetPageList: (data) =>
    // {
    //   dispatch(Actions.GetPageListRequest(data));
    // },
    // SetPageLocal: (data) =>
    // {
    //   dispatch(Actions.SetPageLocal(data));
    // },
    // GetSetting: () =>
    // {
    //   dispatch(Actions.GetSettingRequest());
    // },
    // GetSuportReply: () =>
    // {
    //   dispatch(Actions.GetSuportReplyRequest());
    // },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
