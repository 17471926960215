import HeaderV2 from "components/Header/HeaderV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import Layout from "components/layout/Layout";
import LoadingButton from "components/lib/loading";
import React, { useEffect, useRef, useState } from "react";
import { formatNumber } from "service";
import { getInfoUserss, withdrawBalance } from "service/indexNew";

function Withdraw() {
  const [dataUser, setDataUser] = useState({});
  const [amount, setAmount] = useState(0);
  const timerRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const countdown = () => {
      getData()
    };
    timerRef.current = setInterval(countdown, 1000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);
  const getData = async () => {
    let data = await getInfoUserss();
    setDataUser(data)
  }

  const _withdraw = async () => {
    console.log('runnn');
    setLoading(true)
    let res = await withdrawBalance(amount);
    console.log(res);
    console.log(res.code);
    console.log(res.code != 200);
    if (res.code == 200) {
      // if (res?.message) {
        alert('success')
      // } else {
      //   alert('error', "fail")
      // }
    } else {
      alert(res?.message)
    }
    setLoading(false);
  }


  return (
    <Layout>
      <div
        style={{ backgroundColor: "#0D102D" }}
        className="body customers_page flex-grow-1 px-3"
      >
        <div className="body customers_page flex-grow-1 px-3">
          <div className="container-lg ibgate-page">
            <h1>Withdraw</h1>

            <div className="header_page wChoose">
              <a href="#" className="active">
                BFF (BEP20){" "}
                <img
                  height="25"
                  src="images/images/logoBff.png"
                  alt=""
                />
              </a>
            </div>
            <div className="row withdraw_page">
              <div className="form_withdraw">
                <form
                  action="#"
                >
                  <div className="row form_content">
                    <div className="col-md-5">
                      <div className="box">
                        <div className="form_group mb-0">
                          <ul>
                            <li>
                              <span
                                style={{
                                  fontSize: "22px",
                                  fontWeight: "bold",
                                }}
                              >
                                {formatNumber(dataUser?.balanceBff)} BFF
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="form_group mb-0 warning_withdraw">
                          <label for="">
                            <span
                              style={{
                                color: "red",
                              }}
                            >
                              *
                            </span>
                            Amount of money
                          </label>
                          <input
                            name="curentfrom"
                            data-type="money"
                            required
                            min="10"
                            data-currency="USDT"
                            data-price="1"
                            type={"number"}
                            value={amount}
                            onChange={(e) => { setAmount(e.target.value) }}
                            className=" form-control form-control-lg swapInput"
                          />
                        </div>
                      </div>
                    </div>

                    <div style={{maxWidth: 250}}>
                      {
                        loading ?
                          <LoadingButton type="button" className="ant-btn ant-btn-primary" />
                          :
                          <a className="slslslsls" onClick={() => { _withdraw() }}>Send</a>
                      }
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Withdraw;
