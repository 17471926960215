import HeaderV2 from "components/Header/HeaderV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import Layout from "components/layout/Layout";
import Notification from "components/lib/notification";
import React, { useEffect, useRef, useState } from "react";
import { formatNumber } from "service";
import { getInfoUserss, marketing, withdrawMarketing } from "service/indexNew";

function Marketing() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [sttMoldelAffiliate, setSttMoldelAffiliate] = useState(false);
  const [amount, setAmount] = useState(0);
  const [branch, setBranch] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const timerRef = useRef(null);
  const [dataUser, setDataUser] = useState({});
  const [notificationObj, setNotificationObj] = useState({
    show: false,
    stt: true,
    msg: ""
  });

  useEffect(() => {
    const countdown = () => {
      getData()
    };
    timerRef.current = setInterval(countdown, 1000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  const getData = async () => {
    let dataUser = await getInfoUserss();
    setDataUser(dataUser)
  }

  // const _marketing = () => {
  //   console.log('market');
  // }
  const _marketing = async () => {
    setLoading(true)
    let res = await marketing(amount);
    console.log(res);
    if (res.code == 200) {
      if (res?.message) {
        openNotic('success', 'success')
      } else {
        openNotic('error', "fail")
      }
    } else {
      // console.log('----------------', res?.message);
      if (res?.message) {
        // console.log(res?.message);
        openNotic('error', res.message)
      } else {
        openNotic('error', "fail")
      }
      // console.log("Signature not verified");
    }
    setLoading(false);
  }
  const openNotic = (stt, msg) => {
    // console.log({ show: true, stt, msg });
    setNotificationObj({ show: true, stt, msg })
    setTimeout(() => {
      setNotificationObj({ show: false, stt, msg })
    }, 5000);
  };

  const _withdraw = async () => {
    setLoading(true)
    let res = await withdrawMarketing(amount);
    console.log(res);
    if (res.code == 200) {
      if (res?.message) {
        openNotic('success', 'success')
      } else {
        openNotic('error', "fail")
      }
    } else {
      console.log('----------------', res?.message);
      if (res?.message) {
        openNotic('error', res?.message)
      } else {
        openNotic('error', "fail")
      }
      // console.log("Signature not verified");
    }
    setLoading(false);
  }

  return (
    <Layout>
      <div
        style={{ backgroundColor: "#0D102D" }}
        className="body customers_page flex-grow-1 px-3"
      >
        <div className="body customers_page flex-grow-1 px-3">
          <div className="container-lg ibgate-page">
            <h1>Activate the MARKETING package</h1>

            <div className="row list-item lending_list">
              <div className="col-sm-6 col-lg-3 col-md-6">
                <div data-val="deposit" className="box nStbg active ">
                  <p className="mb-4">Marketing</p>
                  <span>
                    {formatNumber(dataUser?.amountMarketing)} <b className="uppercase">BFF</b>{" "}
                  </span>
                  <div className="form_group mt-2">
                    <input
                      name="money"
                      data-type="money"
                      required
                      min="50"
                      data-exchange="25000"
                      data-currency="USDT"
                      data-min="50"
                      className="getMoneyValdUsd form-control form-control-lg"
                      type="text"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      placeholder="Enter the amount"
                    />
                  </div>
                  <a onClick={() => { _marketing() }}>
                    Deposit
                  </a>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 col-md-6">
                <div className="box nStbg">
                  <p className="mb-4">Commission</p>
                  <span>
                    {formatNumber(dataUser?.balanceMarketing)} <b className="uppercase">BFF</b>{" "}
                  </span>
                  <a onClick={_withdraw}>Withdraw</a>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3 col-md-6">
                <div data-val="invest" className="box nStbg">
                  <p>MAXOUT 300%</p>
                  <span>
                    {formatNumber(dataUser?.amountMarketing * 3)} <b className="uppercase">BFF</b>{" "}
                  </span>
                  <br/>
                  <span>
                    Withdrawn: {formatNumber(dataUser?.withdrawBalanceMarketing)} <b className="uppercase">BFF</b>{" "}
                  </span>
                </div>
              </div>

              <div className="col-sm-6 col-lg-3 col-md-6">
                <div data-val="invest" className="box nStbg ">
                  <p className="mb-4">Total sales by branch</p>
                  <span>
                    {formatNumber(dataUser?.totalSaleBranchMarketing)} <b className="uppercase">BFF</b>{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          notificationObj?.show ?
            <Notification status={notificationObj?.stt} msg={notificationObj?.msg} />
            : null
        }
      </div>

    </Layout>
  );
}

export default Marketing;
