
import * as Types from "./../constants/Typesaction";
export const LoginRequest = (data) =>
{
  return (dispatch) =>
  {

    dispatch(Login(data));

  };
};
// get menu trong reducer
export const Login = (result) =>
{
  return {
    type: Types.LOGIN,
    result,
  };
};
export const ResetLogin = () =>
{
  return (dispatch) =>
  {
    dispatch(Login(''));
  };
};
export const LogoutRequest = (result) =>
{
  return (dispatch) =>
  {

    dispatch(Logout());

  };
};
export const Logout = (result) =>
{
  return {
    type: Types.LOGOUT,
    result,
  };
};

export const GetUser = () =>
{
  let user = localStorage.getItem("user");
  if (user && user !== '') {
    user = JSON.parse(user);
    if (user.user) {
      return { "code": 200, "user": user };
    }
    else {
      return { "code": 201 }
    }
  } else {
    return { "code": 201 }
  }
};


export const SetUserRD = (data) =>
{
  return (dispatch) =>
  {

    dispatch(SetUser(data));

  };
};
// get menu trong reducer
export const SetUser = (result) =>
{
  return {
    type: Types.GET_INFO_USER_ONLY_ONE,
    result,
  };
};