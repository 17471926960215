const domain = "https://us-central1-safaty-e20ba.cloudfunctions.net";

const get_price = () => {
  return async function (dispatch) {
    // try {
    //   const res = await fetch(domain + `/get_total_buy`, {
    //     method: "GET",
    //   }).then((res) => res.json());
    //   const { data } = res;
    //   const obj = {
    //     ...data,
    //     marketCap: formatter(data.marketCap.toFixed()),
    //     tokenPrice: formatter(data.tokenPrice),
    //     totalBuy: formatter(data.totalBuy.toFixed()),
    //     totalValueLock: formatter(data.totalValueLock.toFixed()),
    //     burned: formatter(data.burned.toFixed()),
    //     totalValueLockFarm: formatter(data.totalValueLockFarm.toFixed()),
    //   };
    //   dispatch({
    //     type: "GET_TOTAL",
    //     payload: obj,
    //   });
    // } catch (e) {
      dispatch({
        type: "GET_TOTAL",
        payload: null,
      });
    // }
  };
};

const formatter = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export { get_price };
