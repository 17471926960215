import HeaderV2 from "components/Header/HeaderV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import Layout from "components/layout/Layout";
import React from "react";

function Security() {
  return (
    <Layout>
      <div
        style={{ backgroundColor: "#0D102D" }}
        className="body customers_page flex-grow-1 px-3"
      >
        <div className="body customers_page flex-grow-1 px-3">
          <div className="container-lg ibgate-page">
            <h1>Set up level 2 password</h1>

            <div className="row deposit-page">
              <div className="form_bank">
                <form
                  action="https://bfflendinglewlew.info/gate/control/security"
                  method="post"
                >
                  <div className="row form_content">
                    <p>
                      <strong
                        style={{
                          color: "#FFF",
                        }}
                      >
                        Change password Level 2
                      </strong>
                    </p>
                    <div className="col-md-6">
                      <div className="form_group">
                        <label for="">Old Password</label>
                        <input
                          required
                          name="password"
                          value=""
                          className="form-control form-control-lg"
                          type="password"
                        />
                      </div>
                      <a href="https://bfflendinglewlew.info/gate/control/forgot-password-lv2">
                        Forgot your password?
                      </a>
                      <div className="form_group">
                        <label for="">New Password</label>
                        <input
                          required
                          name="newpassword"
                          value=""
                          className="form-control form-control-lg"
                          type="password"
                        />
                      </div>
                    </div>
                  </div>
                  <input
                    type="hidden"
                    value="4266ca97cab6ef88c17d13c7e2339ab5"
                    name="token_key"
                  />
                  <button type="submit">Send</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Security;
