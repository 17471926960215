import HeaderV2 from "components/Header/HeaderV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import Layout from "components/layout/Layout";
import React from "react";

function ProfileV2() {
  return (
    <Layout>
      <div
        style={{ backgroundColor: "#0D102D" }}
        className="body customers_page flex-grow-1 px-3"
      >
        <div className="body customers_page flex-grow-1 px-3">
          <div className="container-lg ibgate-page">
            <h1>My Profile</h1>

            <div className="row deposit-page">
              <div className="form_bank">
                <div
                  className="alert bg bg-danger"
                  style={{
                    color: "#FFF",
                  }}
                >
                  Your account is not KYC verified. To withdraw you need to
                  complete KYC
                </div>
                <form
                  action="https://bfflendinglewlew.info/gate/control/profile"
                  method="post"
                  enctype="multipart/form-data"
                >
                  <div className="row form_content">
                    <p>
                      Membership code: <strong>DIUPJMAY</strong>
                    </p>
                    <div className="col-md-6">
                      <div className="form_group">
                        <label for="">Name</label>
                        <input
                          required
                          name="name"
                          value="iswap636"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="Enter name"
                        />
                      </div>
                      <div className="form_group">
                        <label for="">Phone</label>
                        <input
                          required
                          name="phone"
                          value="03838383847"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder=""
                        />
                      </div>
                      <div className="form_group">
                        <label for="">Email</label>
                        <input
                          disabled
                          value="iswap636@gmail.com"
                          className="form-control form-control-lg"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form_group">
                        <label for="">BFF COIN</label>
                        <input
                          name="tokens"
                          value=""
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="BFF Wallet"
                        />
                      </div>

                      <div className="form_group">
                        <label for="">
                          USTD BEP20 e-wallet for withdrawals
                        </label>
                        <input
                          name="usdt"
                          value=""
                          className="form-control form-control-lg"
                          type="text"
                          placeholder="USDT Wallet"
                        />
                      </div>
                    </div>
                  </div>
                  <input
                    type="hidden"
                    value="bf6a82d20e685406fceacae39e209ff3"
                    name="token_key"
                  />
                  <button type="submit">Send</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ProfileV2;
