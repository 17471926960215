import HeaderV2 from "components/Header/HeaderV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import Layout from "components/layout/Layout";
import LoadingButton from "components/lib/loading";
import React, { useEffect, useRef, useState } from "react";
import { formatNumber } from "service";
import { getInfoUserss, swap } from "service/indexNew";

function Swap() {
  const [dataUser, setDataUser] = useState({});
  const [typeSwap, setTypeSwap] = useState(0);
  const [amount, setAmount] = useState(0);
  const timerRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const countdown = () => {
      getData()
    };
    timerRef.current = setInterval(countdown, 1000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);
  const getData = async () => {
    let data = await getInfoUserss();
    setDataUser(data)
    // console.log(data);
  }
  const _swap = async () => {
    console.log('runnn');
    setLoading(true)
    let res = await swap(amount, typeSwap);
    console.log(res);
    console.log(res.code);
    console.log(res.code != 200);
    if (res.code == 200) {
      alert('success')
      // if (res?.message) {
      //   alert('success', 'success')
      // } else {
      //   alert('error', "fail")
      // }
    } else {
      alert(res?.message)
    }
    setLoading(false);
  }

  return (
    <Layout>
      <div
        style={{ backgroundColor: "#0D102D" }}
        className="body customers_page flex-grow-1 px-3"
      >
        <div className="body customers_page flex-grow-1 px-3">
          <div className="container-lg ibgate-page">
            <h1></h1>

            <div className="row deposit-page deposit-page-crypto">
              <div className="header_page">
                <span className="live_bank active"
                  onClick={() => { setTypeSwap(0) }}
                  style={{
                    opacity: typeSwap == 1 ? 0.3 : 1
                  }}>
                  <a
                    style={{
                      color: "#FFF"
                    }}
                  >
                    USDT to BFF{" "}
                  </a>
                </span>

                <span className="live_bank active"
                  onClick={() => { setTypeSwap(1) }}
                  style={{
                    opacity: typeSwap == 0 ? 0.3 : 1
                  }}>
                  <a
                    style={{
                      color: "#FFF"
                    }}
                  >
                    BFF to USDT
                  </a>
                </span>
              </div>

              <div className="form_bank">
                <h2 className="getTitleBank">Swap USDT To BFF Token</h2>
                <form
                  action="#"
                >
                  <p
                    style={{
                      color: "#FFF",
                    }}
                  >
                    1 USDT = 1 BFF Token
                  </p>
                  <p
                    style={{
                      color: "#FFF",
                    }}
                  >
                    Account balance: {formatNumber(dataUser?.balanceUsdt)} USDT
                  </p>
                  <p
                    style={{
                      color: "#FFF",
                    }}
                  >
                    Account balance: {formatNumber(dataUser?.balanceBff)} BFF
                  </p>
                  <div className="row form_content">
                    <div className="col-md-6">
                      <div className="form_group">
                        <label for="">
                          <i className="fas fa-star-of-life"></i>
                          <span className="getTitleBank"> {typeSwap == 0 ? "USDT" : 'BFF'} </span>
                        </label>
                        <input
                          name="curentfrom"
                          data-type="money"
                          required
                          min="10"
                          data-currency="USDT"
                          data-price="1"
                          type={"number"}
                          value={amount}
                          onChange={(e) => { setAmount(e.target.value) }}
                          className=" form-control form-control-lg swapInput"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form_group mb-0">
                        <label for="">{typeSwap == 1 ? "USDT" : 'BFF'}</label>
                        <input
                          name="curentto"
                          value={typeSwap == 0 ? amount : amount * 0.95}
                          onChange={(e) => { setAmount(e.target.value) }}
                          className="form-control form-control-lg swapTOhere"
                          type={"number"}
                        />
                      </div>
                    </div>
                  </div>
                  {
                    loading ?
                      <LoadingButton type="button" className="ant-btn ant-btn-primary" />
                      :
                      <a className="slslslsls" onClick={() => { _swap() }}>Send</a>
                  }
                  {/* <button onClick={() => { _swap }}>Send</button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Swap;
