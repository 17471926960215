import HeaderV2 from "components/Header/HeaderV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import React from "react";
import "./styles.css";
import Layout from "components/layout/Layout";

function LuckyWheel() {
  return (
    <Layout>
      <div>eee</div>
    </Layout>
  );
}

export default LuckyWheel;
