const domain = "https://us-central1-safaty-e20ba.cloudfunctions.net";

const get_current_loop = () => {
  return async function (dispatch) {
    // try {
    //   const res = await fetch(domain + `/current_loop`, { method: "GET" }).then(
    //     (res) => res.json()
    //   );
    //   dispatch({
    //     type: "GET_CURRENT_LOOP",
    //     payload: { res },
    //   });
    // } catch (e) {
      dispatch({
        type: "GET_CURRENT_LOOP",
        payload: null,
      });
    // }
  };
};

export { get_current_loop };
