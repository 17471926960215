import HeaderV2 from "components/Header/HeaderV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import Layout from "components/layout/Layout";
import React from "react";

function ChangePassword() {
  return (
    <Layout>
        <div
          style={{ backgroundColor: "#0D102D" }}
          className="body customers_page flex-grow-1 px-3"
        >
          <div className="body customers_page flex-grow-1 px-3">
            <div className="container-lg ibgate-page">
              <h1>Change Client Portal Password</h1>

              <div className="row deposit-page">
                <div className="form_bank">
                  <form
                    action="https://bfflendinglewlew.info/gate/control/change-password"
                    method="post"
                  >
                    <div className="row form_content">
                      <div className="form_group">
                        <label for="">Current password</label>
                        <input
                          name="oldpassword"
                          value=""
                          className="form-control form-control-lg"
                          type="password"
                        />
                      </div>

                      <div className="form_group">
                        <label for="">New password</label>
                        <input
                          name="password"
                          value=""
                          className="form-control form-control-lg"
                          type="password"
                        />
                      </div>

                      <div className="form_group">
                        <label for="">Confirm password</label>
                        <input
                          name="confirmpassword"
                          value=""
                          className="form-control form-control-lg"
                          type="password"
                        />
                      </div>
                    </div>
                    <input
                      type="hidden"
                      value="580768b4f47cecc77f24be76e780bb07"
                      name="token_key"
                    />
                    <button type="submit">Send</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    </Layout>
  );
}

export default ChangePassword;
