import HeaderV2 from "components/Header/HeaderV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import Layout from "components/layout/Layout";
import React from "react";

function SwapHistory() {
  return (
    <Layout>
      <div
        style={{ backgroundColor: "#0D102D" }}
        className="body customers_page flex-grow-1 px-3"
      >
        <div className="body customers_page flex-grow-1 px-3">
          <div className="container-lg ibgate-page">
            <h1>SWAP History</h1>

            <div class="header_page wChoose">
              <a
                href="https://bfflendinglewlew.info/gate/control/swap-history?type=bff"
                class="active"
              >
                USDT {">"} BFF
              </a>

              <a
                href="https://bfflendinglewlew.info/gate/control/swap-history?type=usdt"
                class=""
              >
                BFF {">"} USDT
              </a>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="mb-4">
                  <div
                    style={{
                      height: "500px",
                    }}
                    class="table-responsive listSubAccounts"
                  >
                    <table
                      style={{
                        backgroundColor: "#0D102D",
                      }}
                      class="table border mb-0 "
                    >
                      <thead class="table-light fw-semibold">
                        <tr class="align-middle">
                          <th class="th_tb_else text-center">Date created</th>
                          <th class="th_tb_else text-center">SWAP</th>
                        </tr>
                      </thead>
                      <tbody class="getAllSubUser">
                        <tr id="boxSub_127" class="align-middle">
                          <td class="text-center">07/01/2024 </td>
                          <td class="text-center">949 USDT -{">"} 949 BFF</td>
                        </tr>

                        <tr id="boxSub_125" class="align-middle">
                          <td class="text-center">07/01/2024 </td>
                          <td class="text-center">99 USDT -{">"} 99 BFF </td>
                        </tr>

                        <tr id="boxSub_124" class="align-middle">
                          <td class="text-center">07/01/2024 </td>
                          <td class="text-center">800 USDT -{">"} 800 BFF </td>
                        </tr>

                        <tr id="boxSub_123" class="align-middle">
                          <td class="text-center">07/01/2024 </td>
                          <td class="text-center">100 USDT -{">"} 100 BFF </td>
                        </tr>

                        <tr id="boxSub_122" class="align-middle">
                          <td class="text-center">07/01/2024 </td>
                          <td class="text-center">
                            1,662 USDT -{">"} 1,662 BFF{" "}
                          </td>
                        </tr>

                        <tr id="boxSub_118" class="align-middle">
                          <td class="text-center">07/01/2024 </td>
                          <td class="text-center">100 USDT -{">"} 100 BFF </td>
                        </tr>

                        <tr id="boxSub_112" class="align-middle">
                          <td class="text-center">05/01/2024 </td>
                          <td class="text-center">
                            1,995 USDT -{">"} 1,995 BFF{" "}
                          </td>
                        </tr>

                        <tr id="boxSub_109" class="align-middle">
                          <td class="text-center">04/01/2024 </td>
                          <td class="text-center">
                            1,000 USDT -{">"} 1,000 BFF{" "}
                          </td>
                        </tr>

                        <tr id="boxSub_107" class="align-middle">
                          <td class="text-center">03/01/2024 </td>
                          <td class="text-center">100 USDT -{">"} 100 BFF </td>
                        </tr>

                        <tr id="boxSub_106" class="align-middle">
                          <td class="text-center">03/01/2024 </td>
                          <td class="text-center">
                            5,000 USDT -{">"} 5,000 BFF{" "}
                          </td>
                        </tr>

                        <tr id="boxSub_105" class="align-middle">
                          <td class="text-center">03/01/2024 </td>
                          <td class="text-center">
                            1,000 USDT -{">"} 1,000 BFF{" "}
                          </td>
                        </tr>

                        <tr id="boxSub_99" class="align-middle">
                          <td class="text-center">03/01/2024 </td>
                          <td class="text-center">
                            1,000 USDT -{">"} 1,000 BFF{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="nav-pa"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default SwapHistory;
