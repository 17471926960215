const LoadingButton = ({ className }) => {
    return (
        <button className={className} disabled={true}>
            <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </button>
    );
};

export default LoadingButton;