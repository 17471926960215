import * as LINKS from "./constants/Links";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import AuthenticatedRoute from "./pages/AuthenticatedRoute/AuthenticatedRoute ";
import routers from "./routers";
import "antd/dist/antd.min.css";
// import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/index";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_address } from "store/address_web3/action";
import { get_current_loop } from "store/current_loop/action";
import { get_price } from "store/price/action";
import { get_percent_farm } from "store/percent_farm/action";
// import { get_user } from "store/user/action";
import {
  getAllLaunchpadSever,
  getListTrending,
  getWeb3Provider,
  jsonPares,
} from "service";
import ModalNetwork from "components/lib/modalNetwork";
import Web3 from "web3";
import LinkPro from "components/lib/LinkPro";
import WalletConnectM from "components/lib/walletConnectM";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal, useWeb3Modal } from "@web3modal/react";
import {
  configureChains,
  createConfig,
  WagmiConfig,
  useAccount,
  useDisconnect,
} from "wagmi";

import { arbitrum, mainnet, polygon, bsc, bscTestnet } from "wagmi/chains";
import { useGlobalVar } from "service/globalVar";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import Trending from "components/lib/trending";
import Logo from "components/Logo/Logo";
import { getInfoUser } from "./service";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import Demo from "pages/demo/demo";
import ChangePassword from "pages/change-password/ChangePassword";
import Dashboard from "pages/dashboard/Dashboard";
import Lending from "pages/lending/Lending";
import Marketing from "pages/marketing/Marketing";
import Deposit from "pages/deposit/Deposit";
import Withdraw from "pages/withdraw/Withdraw";
import Swap from "pages/swap/Swap";
import SwapHistory from "pages/swap-history/SwapHistory";
import DepositHistory from "pages/deposit-history/DepositHistory";
import StakingHistory from "pages/network/StakingHistory";
import TransferMoney from "pages/transfer-money/TransferMoney";
import TransferMoneyHistory from "pages/transfer-money-history/TransferMoneyHistory";
import IbLevel from "pages/ib-level/IbLevel";
import Referral from "pages/referral/Referral";
import { Profile } from "react-facebook";
import ProfileV2 from "pages/profileV2/ProfileV2";
import Security from "pages/security/Security";
import LuckyNumber from "pages/lucky-number/LuckyNumber";
import { updateInfoUserss } from "service/indexNew";
import Network from "pages/network/StakingHistory";
import LuckyWheel from "pages/lucky-wheel/LuckyWheel";
const url = window.location.href;
const lastSlashIndex = url.lastIndexOf("/");
const addressWithRefId = url.substring(lastSlashIndex + 1);
const [refId, queryString] = addressWithRefId.split("?");
const params = new URLSearchParams(queryString);
const chianName = params.get("chain");
// console.log(chianName);

let chains;
switch (chianName) {
  case "ARB":
    chains = [arbitrum, bsc, mainnet, polygon];
    break;
  case "ETH":
    chains = [mainnet, arbitrum, bsc, polygon];
    break;
  case "BSC-Test":
    chains = [bscTestnet, mainnet, arbitrum, bsc, polygon];
    break;
  default:
    chains = [bsc, mainnet, arbitrum, polygon];
    break;
}
// console.log(chains);

const projectId = "564c993e5bf943e846da81d17b9c1f5f";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
// console.log(publicClient);
// let obj = {};
// obj.slsll = publicClient;
// console.log(obj);
// window.localStorage.setItem('slsll', JSON.stringify(obj))
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

// console.log(ethereumClient);
const ShowChain = ({ chainId }) => {
  switch (chainId) {
    case 1:
      return (
        <div className="network">
          <img
            src="/static/media/ic-eth.9270fc02.svg"
            width="24"
            alt="chain-name"
          />
          <span className="ml-2 hide-on-mobile text-uppercase font-weight-bold">
            ETH MAINNET
          </span>
        </div>
      );
    case 56:
      return (
        <div className="network">
          <img
            src="/static/media/ic-bsc.419dfaf2.png"
            width="24"
            alt="chain-name"
          />
          <span className="ml-2 hide-on-mobile text-uppercase font-weight-bold">
            BSC MAINNET
          </span>
        </div>
      );
    case 97:
      return (
        <div className="network">
          <img
            src="/static/media/ic-bsc.419dfaf2.png"
            width="24"
            alt="chain-name"
          />
          <span className="ml-2 hide-on-mobile text-uppercase font-weight-bold">
            BSC TESTNET
          </span>
        </div>
      );
    case 42161:
      return (
        <div className="network">
          <img
            src="/static/media/ic-arbitrum.10a7db75.svg"
            width="24"
            alt="chain-name"
          />
          <span className="ml-2 hide-on-mobile text-uppercase font-weight-bold">
            Arbitrum
          </span>
        </div>
      );
    default:
      return (
        <div className="network">
          <img
            src="/static/media/SolanaChain.png"
            width="24"
            alt="chain-name"
          />
          <span className="ml-2 hide-on-mobile text-uppercase">
            Solana Chain
          </span>
        </div>
      );
  }
};

const AppRoute = ({ component: Component, exact, title, ...rest }) => {
  return (
    <Route
      exact={exact}
      {...rest}
      render={(props) => (
        <>
          <Component {...props} />
        </>
      )}
    />
  );
};

const App = (props) => {
  const state = useSelector((state) => state);
  const savedCallback = useRef();
  const dispatch = useDispatch();
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const address = useSelector((state) => state.address_web3);
  const user = useSelector((state) => state.user);
  const [isToggle, setIsToggle] = useState(false)
  const [chianId, setChainId] = useState(56)
  const [arrTrending, setArrTrending] = useState([])
  const [isModalNetwork, setIsModalNetwork] = useState(false)
  const [openModalWallet, setOpenModalWallet] = useState(false);
  const { providerVar, setProviderVar } = useGlobalVar();
  // const { open } = useWeb3Modal();
  // const { isConnected } = useAccount();
  // const { disconnect } = useDisconnect();

  const onConnect = (type_) => {
    console.log(type_);
    switch (type_) {
      case "wallet":
        // onOpen();
        break;

      default:
        if (window.ethereum) {
          window.ethereum.enable().then(() => { });
        }
        break;
    }
  };
  const getChainId = async () => {
    if ((window.localStorage.getItem("providerType") == "wallet")) {
      // const web3 = new Web3(window.ethereum);
      const web3 = await getWeb3Provider(Web3.givenProvider);
      web3.eth.net.getId()
        .then(chainId => {
          setChainId(chainId)
          window.localStorage.setItem("chainId", chainId)
        })
        .catch(error => {
          console.error('Error getting chain ID:', error);
        });
    } else if (typeof window.ethereum !== 'undefined') {
      const web3 = new Web3(window.ethereum);
      web3.eth.net.getId()
        .then(chainId => {
          setChainId(chainId)
          window.localStorage.setItem("chainId", chainId)
        })
        .catch(error => {
          console.error('Error getting chain ID:', error);
        });
    } else {
      console.error('Web3 not found.');
    }
  }


  // useEffect(() => {
  //   let arrTrending_ = arrTrending;
  //   setInterval(async () => {
  //     let dataa_ = await getListTrending();
  //     // console.log("dataa_dataa_",dataa_,arrTrending_);
  //     if (dataa_) {
  //       if (dataa_.length != arrTrending_.length) {
  //         arrTrending_ = dataa_;
  //         setArrTrending([...dataa_])
  //       }

  //     }
  //     // console.log('ssss');
  //   }, 1000);
  // }, [state?.address_web3]);

  const get_user = (address) => {
    setInterval(async () => {
      // console.log('run');
      if (window.localStorage.getItem("isLogin") != 'false') {
        try {
          const abc = await getInfoUser();
          // console.log('abc ', abc);
          if (abc.balanceBNB && abc.balanceBNB !== state.user.balanceBNB) {
            dispatch({
              type: "GET_USER",
              payload: abc,
            });
          }

        } catch (e) {
          // dispatch({
          //   type: "GET_USER",
          //   payload: null,
          // });

        }
      }
    }, 1000)
  }
  useEffect(() => {
    const handleChainChanged = async () => {
      window.location.reload();
    };

    if (window.ethereum) {
      window.ethereum.on('chainChanged', handleChainChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, []);

  useEffect(async () => {
    const url = window.location.href;
    const lastSlashIndex = url.lastIndexOf("/");
    const addressWithRefId = url.substring(lastSlashIndex + 1);
    const [refId, queryString] = addressWithRefId.split("?");
    const params = new URLSearchParams(queryString);
    const chianName = params.get("chain");
    // console.log(chianName);
    console.log(chianName);
    let chains, optionalChains;
    switch (chianName) {
      case "ARB":
        chains = [42161];
        optionalChains = [42161, 56, 1];
        break;
      case "ETH":
        chains = [1];
        optionalChains = [1, 42161, 56];
        break;
      case "BSC-Test":
        chains = [97];
        optionalChains = [97, 1, 42161, 56];
        break;
      default:
        chains = [56];
        optionalChains = [56, 1, 42161];
        break;
    }

    const provider = await EthereumProvider.init({
      projectId: "564c993e5bf943e846da81d17b9c1f5f", // REQUIRED your projectId
      // chains, // REQUIRED chain ids
      // optionalChains, // OPTIONAL chains
      showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal
      qrModalOptions: {
        themeVariables: {
          '--w3m-z-index': "9999",
        }
      }
    })
    console.log(provider);
    await provider.enable();
    if (provider) {
      lestienwalletconenct(provider)
    }

    const result = await provider.request({ method: 'eth_requestAccounts' })
    console.log(result);

  }, []);

  const lestienwalletconenct = (providersss) => {
    console.log(providersss);
    const handleChainChanged = async () => {
      window.location.reload();
    };
    console.log(providersss);
    if (providersss) {
      providersss.on('chainChanged', handleChainChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  };
  //new
  useEffect(() => {
    updateInfoUserss()
    setInterval(() => {
      updateInfoUserss()
    }, 1000);
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.enable().then(() => { });
      dispatch(get_address());
      dispatch(get_current_loop());
      dispatch(get_price());
      dispatch(get_percent_farm());
      (get_user(state?.address_web3?.toLowerCase()));

      getChainId()
      getAllLaunchpadSever()
    } else if (window.localStorage.getItem("providerType") == "wallet") {
      getChainId()
      getAllLaunchpadSever()
      dispatch(get_address());
      (get_user(""));
    }
    // getAllLaunchpadSever()
  }, [window.ethereum, providerVar, window.localStorage.getItem("providerType")]);

  // useEffect(() => {
  //   if (state.address_web3) {
  //     dispatch(get_user(state.address_web3.toLowerCase()));
  //     if (state.address_web3 !== savedCallback.current) {
  //       clearInterval(savedCallback.current);
  //     }
  //     savedCallback.current = state.address_web3;
  //     savedCallback.current = setInterval(() => {
  //       dispatch(get_user(state.address_web3.toLowerCase()));
  //       // console.clear();
  //     }, 3000);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state.address_web3]);

  // useEffect(async () => {

  //   setInterval(async () => {
  //     let dataa_ = await getListTrending();
  //     if (dataa_) {
  //       setArrTrending(dataa_)
  //     }
  //     console.log('ssss');
  //   }, 5000);
  // }, [state?.address_web3]);

  const selectNetwork = async (network_, newChain) => {
    // console.log(network_);
    if (window.localStorage.getItem("providerType") == "wallet") {
      return
    }

    let web3 = new Web3(window.ethereum);

    try {
      if (web3.currentProvider) {
        // console.log(web3);
        // console.log(web3.currentProvider);
        await web3.currentProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: network_ }], // Change to the desired chain ID
        });
      }

      const url = new URL(window.location);
      url.searchParams.set('chain', newChain);
      window.location.replace(url.toString());
    } catch (error) {
      console.error('Error switching network:', error);
    }

  }

  const logout = async () => {

    // window.localStorage.setItem("isLogin", 'false')

    try {
      // if (providerVar && providerVar.isConnected()) {
      // await providerVar.close();
      setProviderVar(null);
      window.localStorage.clear()
      window.localStorage.setItem("isLogin", 'false')
      window.localStorage.setItem("providerType", "")
      console.log("Disconnected successfully.");
      // } else {
      //   console.log("Provider not connected.");
      // }
    } catch (error) {
      console.error("Error disconnecting:", error);
    }

    // await providerVar.disconnectSession({
    //   topic,
    //   reason: getSdkError('USER_DISCONNECTED')
    // })
  }
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/lending" component={Lending} />
        <Route path="/community" component={Marketing} />
        <Route path="/deposit" component={Deposit} />
        <Route path="/withdraw" component={Withdraw} />
        <Route path="/swap" component={Swap} />
        <Route path="/swap-history" component={SwapHistory} />
        <Route path="/history" component={DepositHistory} />
        <Route path="/network" component={Network} />
        <Route path="/transfer-money" component={TransferMoney} />
        <Route path="/transfer-money-history" component={TransferMoneyHistory} />
        <Route path="/ib-level" component={IbLevel} />
        <Route path="/referral" component={Referral} />
        <Route path="/profile" component={ProfileV2} />
        <Route path="/security" component={Security} />
        <Route path="/lucky-number" component={LuckyNumber} />
        <Route path="/lucky-wheel" component={LuckyWheel} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
