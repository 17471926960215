import SidebarV2 from "components/Sidebar/SidebarV2";
import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import HeaderV2 from "components/Header/HeaderV2";
import Layout from "components/layout/Layout";
import { getInfoUserss } from "service/indexNew";
import { formatNumber } from "service";
import { useSelector } from "react-redux";

function Dashboard() {
  const state = useSelector((state) => state);
  const [dataUser, setDataUser] = useState({});
  const timerRef = useRef(null);

  useEffect(() => {
    const countdown = () => {
      getData()
    };
    timerRef.current = setInterval(countdown, 1000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);
  const getData = async () => {
    let data = await getInfoUserss();
    setDataUser(data)
    // console.log(data);
  }

  const handleCopy = (type) => {
    const input = document.createElement("input");
    input.style.opacity = 0;
    input.style.position = "absolute";
    input.value = `${window.location.origin}?ref=${state?.address_web3}&branch=${type}`;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    // if (_data) {
    //     openNotic('success', "Pool has been canceled")
    // }
    alert('copied successfully')
  };

  // console.log(dataUser);
  return (
    <Layout>
      <div
        style={{ backgroundColor: "#0D102D" }}
        className="body customers_page flex-grow-1 px-3"
      >
        <div className="container-lg ibgate-page">
          <h1>
            Dashboard
            <span
              className="copytext codeMyCopy"
            // onclick="copyText('#codeCopy', 'Copy success')"
            >
              <i className="far fa-copy"></i>
            </span>
          </h1>
          <div className="row header_ib">
            {/* <!-- <div className="col-md-12">
    <div className="socialBox">
                </div>
  </div> --> */}
            {/* <div className="col-md-3 w-45 pdr-0">
              <div className="box nStbg lslslsldlasdsa">
                <h3>BFF wallet</h3>
                <span>{formatNumber(dataUser?.balanceBff)} BFF</span>
                <a
                  className="withdraw"
                  href="/swap"
                >
                  SWAP
                </a>
              </div>
            </div> */}
            <div className="col-md-6 w-45 pdr-0">
              <div className="box nStbg lslslsldlasdsa">
                <div>- Address: {dataUser?.address?.slice(0, 4)}...{dataUser?.address?.slice(-6)}</div>
                <div>- RefBy: {dataUser?.refBy?.slice(0, 4)}...{dataUser?.refBy?.slice(-6)}</div>
                <div>- Balance BFF: {formatNumber(dataUser?.balanceBff)} BFF</div>
                {/*<div>- Balance USDT: {formatNumber(dataUser?.balanceUsdt)} USDT</div>*/}
                <div onClick={() => { handleCopy(0) }}>- Link Ref L: ...{`${state?.address_web3?.slice(-6)}&branch=0`} <span style={{ fontSize: 16, marginLeft: 10 }}>Copy</span></div>
                <div onClick={() => { handleCopy(1) }}>- Link Ref R: ...{`${state?.address_web3?.slice(-6)}&branch=1`} <span style={{ fontSize: 16, marginLeft: 10 }}>Copy</span></div>

              </div>
            </div>
            <div className="col-md-6 w-45 pdr-0">
              <div className="box nStbg lslslsldlasdsa">
                <h3>BFF wallet</h3>
                <span>{formatNumber(dataUser?.balanceBff)} BFF</span>
                <a
                  className="withdraw"
                  href="/withdraw"
                >
                  Withdraw
                </a>
                <a
                  className="withdraw"
                  href="/deposit"
                >
                  Deposit
                </a>
              </div>
            </div>

            {/* <div className="col-md-3 w-45 pdl-0">
              <div className="box nStbg lslslsldlasdsa">
                <div style={{textAlign: 'center'}}>
                  <img style={{maxHeight: 100}} src="images/logoBff.png" />
                </div>
                <div style={{ fontSize: 20, textAlign:'center', fontWeight: 700, marginTop: 10 }}>1 BFF = 1.4 USDT</div>
                
              </div>
              
            </div> */}
          </div>

          <div className="row list-item">
            {/* <!-- <div className="col-sm-6 col-lg-3 col-md-6">
          <div className="box nStbg " style="height: auto;">
            <p>Total commission you get</p>
            <span className="hoahong">0 BBN</span>		
            
          </div>
        </div>--> */}
            <div className="col-sm-6 col-lg-4 col-md-6">
              <div
                className="box nStbg"
                style={{
                  height: "auto",
                }}
              >
                <p>Commissions game</p>
                <span>{formatNumber(dataUser?.gameCommission)} BFF</span>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 col-md-6">
              <div
                className="box nStbg"
                style={{
                  height: "auto",
                }}
              >
                <p>Commission lending</p>
                <span>{formatNumber(dataUser?.lendingCommission)} BFF</span>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4 col-md-6">
              <div
                className="box nStbg"
                style={{
                  height: "auto",
                }}
              >
                <p>Commission marketing</p>
                <span>{formatNumber(dataUser?.marketingCommission)} BFF</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
