import HeaderV2 from 'components/Header/HeaderV2'
import SidebarV2 from 'components/Sidebar/SidebarV2'
import React from 'react'

function Layout(props) {
  return (
    <div>
       <SidebarV2 />
       <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        {/* hearder */}
        <HeaderV2 />
        {
          props.children
        }
      </div>
    </div>
  )
}

export default Layout
