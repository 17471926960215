import React, { useEffect, useState } from "react";
import "./styles.css";

function LuckyNumber() {
  const [rangeValue, setRangeValue] = useState(5);
  const [rangeValueNum, setRangeValueNum] = useState(0);
  const [isOver, setisOver] = useState(false);
  const [setStart, setSetStart] = useState(null);
  const [resultnum, setresultnum] = useState(null);

  useEffect(() => {
    document.getElementById("rs-range-line").addEventListener("input", function (event) {
      // Lấy giá trị mới của thanh trượt
      // const max=98;
      var newValue = 1 * event.target.value;
      // const wrap=document.getElementById("range-slider-mobile");
      // const width=wrap.offsetWidth;
      // console.log("widthwidth",width);
      // const vl=max*newValue/width;

      setRangeValue(newValue);
      setRangeValueNum(1 * newValue - 5)

    });
    return () => {

    };
  }, []);

  const startGame = () => {
    setresultnum(null);
    const max = 98;
    const min = 5;

    // Hàm này sinh số ngẫu nhiên và cập nhật giá trị setStart
    const generateRandomNumber = () => {
      const rand = Math.floor(Math.random() * (max - min + 1)) + min;
      setSetStart(rand);
    };

    // Gọi hàm sinh số ngẫu nhiên ban đầu
    generateRandomNumber();

    // Thiết lập lặp lại sau mỗi 3 giây
    const intervalId = setInterval(generateRandomNumber);
    setTimeout(() => {
      clearInterval(intervalId);

      const generateRandomNumber = () => {
        const rand = Math.floor(Math.random() * (max - min + 1)) + min;
        // setSetStart(rand);
        setresultnum(rand);
      };
      generateRandomNumber();
    }, 3000);

  }
  return (
    <div className="game_over_lay">
      {/* <input type={"range"} /> */}
      <div className="preloader" id="preloader"></div>
      <section>
        <div className="container-fruid wow fadeInUp">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-3">
                <div className="header-logo">
                  <a
                    className="site-logo site-title"
                    href="https://bfflendinglewlew.info/gate/control/dashboard"
                  >
                    <img
                      style={{
                        height: "60px",
                      }}
                      src="images/logoBff.png"
                      alt=""
                      title=""
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-9">
                <div className="wallet">
                  <div className="box">
                    <div className="balance">
                      <span>
                        <strong id="myBalaceUSDT">1,607.086</strong> BFF
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className=" pt-120">
          <div className="container-fruid wow fadeInUp">
            <div className="container">
              <div className="playgame">
                <div className="row">
                  <div className="col-md-8">
                    <div className="mainBox">
                      <div className="roll-result">
                        <p id="getResultLuckymumber">{setStart ? setStart : ""}</p>
                      </div>
                      {resultnum && <div className="showResult">
                        <span className="setNumber">{resultnum}</span>
                        <span className="winnumber win">68</span>
                      </div>}

                      <div className="updown row">
                        <button data-value="DOWN" aria-current="false" onClick={() => setisOver(false)}>
                          <i className="fas fa-chevron-down"></i> Under
                        </button>
                        <button data-value="UP" aria-current="true" onClick={() => setisOver(true)}>
                          <i className="fas fa-chevron-up"></i> Over
                        </button>
                      </div>

                      <div className="range-slider-mobile" id="range-slider-mobile">
                        <span
                          id="rs-bullet"
                          className="rs-label"
                          style={{
                            left: `${rangeValueNum}%`,
                          }}
                        >
                          {rangeValue}
                        </span>
                        <input
                          id="rs-range-line"
                          className="rs-range"
                          type="range"
                          value={rangeValue}
                          min="5"
                          max="98"
                          style={{
                            backgroundImage: !isOver ?
                              "linear-gradient(to right, transparent 0%, transparent " + rangeValue + "%, rgb(43, 154, 171) " + rangeValue + "%, rgb(43, 154, 171) 100%)" :
                              "linear-gradient(to left, transparent 0%, transparent " + (100 - 1 * rangeValue) + "%, rgb(43, 154, 171) " + (100 - 1 * rangeValue) + "%, rgb(43, 154, 171) 100%)"
                            ,
                          }}
                        />
                      </div>
                    </div>
                    <div className="minmax">
                      <b
                        id="minRange"
                        style={{
                          float: "left",
                        }}
                      >
                        5
                      </b>
                      <b
                        id="maxRange"
                        style={{
                          float: "right",
                        }}
                      >
                        98
                      </b>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <form action="#">
                      <div className="item">
                        <label for="">BFF Amount</label>
                        <div className="fc bff">
                          <input
                            type="text"
                            data-type="money"
                            id="usdtAmount"
                            data-min="1"
                            data-max="1607.09"
                            className="form-control"
                            placeholder="0"
                          />
                          <div className="amount-events">
                            <button data-change="2">x2</button>
                            <button data-change="0.5">1/2</button>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <label for="">Win Rate</label>
                        <div className="wr">
                          <input type="number" disabled="" id="winrate" />
                        </div>
                      </div>
                      <div className="item">
                        <label for="">Payout</label>
                        <div className="fc bff">
                          <input type="text" disabled="" id="myPayout" />
                        </div>
                      </div>
                      <div className="item">
                        <input type="hidden" id="typeScore" value="12" />
                        <input type="hidden" id="typeRate" value="UP" />
                        <input type="hidden" id="typeCrystal" value="bff" />
                        <span className="action" id="pLuckyGameNumber" onClick={startGame}>
                          Play{" "}
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="latest-activites-section">
        <div className="pb-120">
          <div className="container-fruid wow fadeInUp">
            <div className="container">
              <div className="history_game">
                <div className="table-responsive getDataNew">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Position</th>
                        <th>Time</th>
                        <th>Type</th>
                        <th>Player's number</th>
                        <th>Winning number</th>
                        <th>Bet amount</th>
                        <th>Rate</th>
                        <th>Win Money</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="win">
                        <td>1</td>
                        <td>17/03/2024 09:43:15</td>
                        <td>UP</td>
                        <td>12</td>
                        <td>68</td>
                        <td>1 BFF</td>
                        <td>1.09</td>
                        <td>0.0855 BFF</td>
                      </tr>
                      <tr className="lose">
                        <td>2</td>
                        <td>17/03/2024 08:24:28</td>
                        <td>UP</td>
                        <td>49</td>
                        <td>40</td>
                        <td>10 BFF</td>
                        <td>1.9</td>
                        <td>0 BFF</td>
                      </tr>
                      <tr className="win">
                        <td>3</td>
                        <td>17/03/2024 08:24:19</td>
                        <td>UP</td>
                        <td>49</td>
                        <td>94</td>
                        <td>10 BFF</td>
                        <td>1.9</td>
                        <td>8.55 BFF</td>
                      </tr>
                      <tr className="lose">
                        <td>4</td>
                        <td>16/03/2024 12:38:50</td>
                        <td>DOWN</td>
                        <td>57</td>
                        <td>78</td>
                        <td>10 BFF</td>
                        <td>1.67</td>
                        <td>0 BFF</td>
                      </tr>
                      <tr className="win">
                        <td>5</td>
                        <td>16/03/2024 12:38:32</td>
                        <td>DOWN</td>
                        <td>57</td>
                        <td>13</td>
                        <td>10 BFF</td>
                        <td>1.67</td>
                        <td>6.365 BFF</td>
                      </tr>
                      <tr className="lose">
                        <td>6</td>
                        <td>16/03/2024 12:38:26</td>
                        <td>DOWN</td>
                        <td>57</td>
                        <td>79</td>
                        <td>10 BFF</td>
                        <td>1.67</td>
                        <td>0 BFF</td>
                      </tr>
                      <tr className="lose">
                        <td>7</td>
                        <td>16/03/2024 11:05:59</td>
                        <td>UP</td>
                        <td>38</td>
                        <td>5</td>
                        <td>10 BFF</td>
                        <td>1.56</td>
                        <td>0 BFF</td>
                      </tr>
                      <tr className="lose">
                        <td>8</td>
                        <td>16/03/2024 11:05:47</td>
                        <td>UP</td>
                        <td>38</td>
                        <td>14</td>
                        <td>10 BFF</td>
                        <td>1.56</td>
                        <td>0 BFF</td>
                      </tr>
                      <tr className="win">
                        <td>9</td>
                        <td>16/03/2024 11:03:40</td>
                        <td>UP</td>
                        <td>50</td>
                        <td>68</td>
                        <td>10 BFF</td>
                        <td>1.94</td>
                        <td>8.93 BFF</td>
                      </tr>
                      <tr className="lose">
                        <td>10</td>
                        <td>16/03/2024 11:03:32</td>
                        <td>UP</td>
                        <td>50</td>
                        <td>4</td>
                        <td>10 BFF</td>
                        <td>1.94</td>
                        <td>0 BFF</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer id="footer-section">
        <div className="">
          <div className="container">
            <div className="row">
              <div className="col-lg-12"></div>
            </div>
            <div className="bottom-area pt-120">
              <div className="row d-flex header-area">
                <div className="col-lg-8 col-md-8 d-flex justify-content-md-between justify-content-center align-items-center">
                  <div className="logo-section">
                    <a className="site-logo site-title" href="index.html">
                      <img
                        style={{
                          height: '100px'
                        }}
                        src="images/logoBff.png"
                        alt="site-logo"
                      />
                    </a>
                  </div>
                  <ul className="navbar-nav main-menu d-flex align-items-center"></ul>
                </div>
                <div className="col-lg-4 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end">
                  <div className="social-area d-flex">
                    <a href="">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                    <a href="">
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a href="">
                      <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                    <a href="">
                      <i className="fa-brands fa-telegram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal register fade"
          id="signUpModalLong"
          tabindex="-1"
          role="dialog"
          aria-labelledby="signUpModalLongTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="signUpModalLongTitle">
                  <img
                    style={{
                      height: '50px'
                    }}
                    src="images/logoBff.png"
                    alt="image"
                  />
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src="https://bfflendinglewlew.info/image/cross-icon-1.png"
                    alt="image"
                  />
                </button>
              </div>
              <div className="modal-body">
                <h5 className="welcome"></h5>
                <p>
                  {" "}
                  <button
                    type="button"
                    className="cmn-btn"
                    data-toggle="modal"
                    data-target="#signInModalLong"
                  >
                    Sign In
                  </button>
                </p>
                <div className="form-area">
                  <form id="registerForm" method="post" action="#">
                    <div className="message"></div>
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        className="form-control"
                        name="name"
                        required=""
                        placeholder="Your Name"
                        type="text"
                      />
                    </div>
                    <div className="form-group">
                      <label>Email Address</label>
                      <input
                        className="form-control"
                        id="emailRegister"
                        name="email"
                        required=""
                        placeholder="Registered Email Address."
                        type="email"
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        className="form-control"
                        id="passFil"
                        name="password"
                        required=""
                        placeholder="Password"
                        type="password"
                      />
                    </div>
                    <div className="form-group">
                      <label>Confirm Password</label>
                      <input
                        className="form-control"
                        id="cpassFil"
                        name="pre-password"
                        required=""
                        placeholder="Password"
                        type="password"
                      />
                    </div>
                    <div className="form-group">
                      <label>Referral Code</label>
                      <input
                        className="form-control"
                        value=""
                        name="referral"
                        id="referralCode"
                        placeholder="Referral code"
                        type="text"
                      />
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        id="btnRegister"
                        className="cmn-btn cmn-btn-alt"
                      >
                        {" "}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal register fade"
          id="signInModalLong"
          tabindex="-1"
          role="dialog"
          aria-labelledby="signInModalLongTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="signInModalLongTitle">
                  <img
                    style={{ height: "50px" }}
                    src="images/logoBff.png"
                    alt="image"
                  />
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src="https://bfflendinglewlew.info/image/cross-icon-1.png"
                    alt="image"
                  />
                </button>
              </div>
              <div className="modal-body">
                <h5></h5>
                <p>
                  {" "}
                  <a href="https://bfflendinglewlew.info/accounts/register">
                    Singup
                  </a>
                </p>
                <div className="form-area">
                  <form id="loginForm" method="post" action="#">
                    <div className="form-group">
                      <label>Email Address</label>
                      <input
                        className="form-control"
                        name="email"
                        required=""
                        placeholder="Email Address."
                        type="email"
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        className="form-control"
                        name="password"
                        required=""
                        placeholder="Password"
                        type="password"
                      />
                    </div>
                    <div className="form-group d-flex justify-content-end">
                      <label>
                        <a href="https://bfflendinglewlew.info/user/getpassword"></a>
                      </label>
                    </div>
                    <div className="form-group d-flex">
                      <div className="form-group">
                        <button
                          type="submit"
                          id="btnLogin"
                          className="cmn-btn cmn-btn-alt"
                        >
                          {" "}
                          Login
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default LuckyNumber;
