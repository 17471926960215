import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";

import * as LINK from "./../../../constants/Links";
import Notic from "../notic"
import { Container, Row, Col } from "react-bootstrap";
import { Route, Switch, Link, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import * as Actions from "./../../../actions/index";
import {
    formatNumber,
    getInfoToken,
    contractLock,
    contractLockPink,
    allVarChain
} from "../../../service";
import Guide from "components/lib/guide";

function isInteger(value) {
    if (typeof value !== 'number') {
        return false;
    }
    if (value % 1 !== 0) {
        return false;
    }
    return true;
}

const Staking = ({ fcSetData, data, nextS }) => {
    const state = useSelector((state) => state);
    const [preRate, setPreRate] = useState(data?.preRate);
    const [whiteList, setWhiteList] = useState(data?.whiteList);
    const [vesting, setVesting] = useState(data?.vesting);
    const [liquidityLockDay, setLiquidityLockDay] = useState(data?.liquidityLockDay);
    const [softCap, setSoftCap] = useState(data?.softCap);
    const [hardCap, setHardCap] = useState(data?.hardCap);
    const [totalToken, setTotalToken] = useState(data?.totalToken);
    const [minBuy, setMinBuy] = useState(data?.minBuy);
    const [maxBuy, setMaxBuy] = useState(data?.maxBuy);
    const [typeRefund, setTypeRefund] = useState(data?.typeRefund);
    const [liquidityRate, setLiquidityRate] = useState(data?.liquidityRate);
    const [listingRate, setListingRate] = useState(data?.listingRate);
    const [startTime, setStartTime] = useState(dayjs(data?.startTime != "0" ? new Date(data?.startTime) : new Date()));
    const [endTime, setEndTime] = useState(dayjs(data?.endTime != "0" ? new Date(data?.endTime) : new Date()));
    const [balanceToken, setBalanceToken] = useState(data?.preRate);

    useEffect(() => {
        let totalTKS;
        if (data.listingOption != "0") {
            totalTKS = (preRate * hardCap)
        } else {
            if (data.feeOption != "0") {
                totalTKS = (preRate * hardCap) + (hardCap * (1 - ((1 + (data.affiliate * 1)) / 100)) * liquidityRate * listingRate / 100)
            } else {
                totalTKS = (preRate * hardCap) + (hardCap * (1 - ((1 + (data.affiliate * 1)) / 100)) * liquidityRate * listingRate / 100)
            }
        }
        if (data.feeOption != "0") {
            totalTKS += (preRate * hardCap * 0.01)
        }

        setTotalToken(totalTKS)
    }, [softCap, hardCap, listingRate, preRate, liquidityRate]);

    useEffect(() => {
        data.lock = contractLock;
        data.router = allVarChain?.createTokenRouterExchangePancake;
        fcSetData(data);
    }, [])

    const saveAll = () => {
        let totalTKS;
        if (data.listingOption != "0") {
            totalTKS = (preRate * hardCap)
        } else {
            if (data.feeOption != "0") {
                totalTKS = (preRate * hardCap) + (hardCap * (1 - ((1 + (data.affiliate * 1)) / 100)) * liquidityRate * listingRate / 100)
            } else {
                totalTKS = (preRate * hardCap) + (hardCap * (1 - ((1 + (data.affiliate * 1)) / 100)) * liquidityRate * listingRate / 100)
            }
        }
        if (data.feeOption != "0") {
            totalTKS += (preRate * hardCap * 0.01)
        }

        data.preRate = preRate;
        data.whiteList = whiteList;
        data.vesting = vesting;
        data.liquidityLockDay = liquidityLockDay;
        data.softCap = softCap;
        data.hardCap = hardCap;
        data.totalToken = totalTKS;
        data.minBuy = minBuy;
        data.maxBuy = maxBuy;
        data.typeRefund = typeRefund;
        data.liquidityRate = liquidityRate;
        data.listingRate = listingRate;
        data.startTime = startTime;
        data.endTime = endTime;
        fcSetData(data);
    }

    const roundTimeToMinute = (_date) => {
        _date.setSeconds(0);
        _date.setMilliseconds(0);
        return _date;
    }

    return (
        <div className="pop-page">
            <div className="ant-card-head" style={{marginBottom:'0px'}}>
                <div className="ant-card-head-wrapper">
                    <div className="ant-card-head-title">Create launchpad</div>
                    <div className="ant-card-head-title ant-card-head-title-book ">
                        {/* <img src='/static/media/book.png' />
                        <span>GUIDE</span> */}
                        <Guide />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-3 col-lg-3 padding-0 bg-disabled">
                    <div className="steps hide-on-tablet">
                        <div className="ant-steps ant-steps-vertical ant-steps-label-vertical">
                            <div className="ant-steps-item ant-steps-item-finish">
                                <div className="ant-steps-item-container">
                                    <div className="ant-steps-item-tail"></div>
                                    <div className="ant-steps-item-icon">
                                        <span className="ant-steps-icon">
                                            <span role="img" style={{ display: 'inline-flex' }} aria-label="check" className="anticon anticon-check ant-steps-finish-icon">
                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                                    <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                    <div className="ant-steps-item-content">
                                        <div className="ant-steps-item-title">Verify Token</div>
                                        <div className="ant-steps-item-description">Enter the token address and verify</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ant-steps-item ant-steps-item-process ant-steps-item-active">
                                <div className="ant-steps-item-container">
                                    <div className="ant-steps-item-tail"></div>
                                    <div className="ant-steps-item-icon">
                                        <span className="ant-steps-icon">2</span>
                                    </div>
                                    <div className="ant-steps-item-content">
                                        {
                                            data?.listingOption == "0" ?
                                                <div className="ant-steps-item-title">DeFi Launchpad Info</div>
                                                :
                                                <div className="ant-steps-item-title">Manual Listing Launchpad Info</div>
                                        }
                                        <div className="ant-steps-item-description">Enter the launchpad information that you want to raise , that should be enter all details about your presale</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ant-steps-item ant-steps-item-wait">
                                <div className="ant-steps-item-container">
                                    <div className="ant-steps-item-tail">
                                    </div>
                                    <div className="ant-steps-item-icon">
                                        <span className="ant-steps-icon">3</span>
                                    </div>
                                    <div className="ant-steps-item-content">
                                        <div className="ant-steps-item-title">Add Additional Info</div>
                                        <div className="ant-steps-item-description">Let people know who you are</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ant-steps-item ant-steps-item-wait">
                                <div className="ant-steps-item-container">
                                    <div className="ant-steps-item-tail"></div>
                                    <div className="ant-steps-item-icon">
                                        <span className="ant-steps-icon">4</span>
                                    </div>
                                    <div className="ant-steps-item-content">
                                        <div className="ant-steps-item-title">Finish</div>
                                        <div className="ant-steps-item-description">Review your information</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-sm-12 col-md-9 col-lg-9 padding-0">
                    <div className="ant-card ant-card-bordered">
                        <div className="ant-card-body">
                            <form>
                                <div className="field">
                                    <label className="label" for="presaleRate">Presale rate<sup className="has-text-danger">*</sup>
                                    </label>
                                    <div className="control">
                                        <input onChange={(event) => { setPreRate(event.target.value) }} className="input" type="number" placeholder="100" id="presaleRate" name="presaleRate" autoComplete="on" value={preRate} />
                                    </div>
                                    <p className="help is-info">If I spend  1 {data?.textCurrency} how many tokens will I receive?</p>
                                </div>

                                <div className="field">
                                    <label for="" className="label">Whitelist</label>
                                    <div className="ant-radio-group ant-radio-group-outline">
                                        <div className="ant-space ant-space-horizontal ant-space-align-center" style={{ gap: 8 }}>
                                            <div className="ant-space-item">
                                                <label className="ant-radio-wrapper" onClick={() => { setWhiteList("0") }}>
                                                    <span className={whiteList == "0" ? "ant-radio ant-radio-checked" : "ant-radio"}>
                                                        <input type="radio" className="ant-radio-input" value="0" />
                                                        <span className="ant-radio-inner"></span>
                                                    </span>
                                                    <span>Disable</span>
                                                </label>
                                            </div>
                                            <div className="ant-space-item">
                                                <label className="ant-radio-wrapper" onClick={() => { setWhiteList("1") }}>
                                                    <span className={whiteList == "1" ? "ant-radio ant-radio-checked" : "ant-radio"}>
                                                        <input type="radio" className="ant-radio-input" value="1" />
                                                        <span className="ant-radio-inner"></span>
                                                    </span>
                                                    <span>Enable</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="has-text-info is-size-7">You can enable/disable whitelist anytime.</p>
                                </div>

                                <div className="columns mb-0">
                                    <div className="column pb-0">
                                        <div className="field">
                                            <label className="label" htmlFor="softCap">Softcap ({data?.textCurrency})<sup className="has-text-danger">*</sup></label>
                                            <div className="control">
                                                <input onChange={(event) => { setSoftCap(event.target.value) }} className="input" type="number" placeholder="Ex: 10" id="softCap" name="softCap" autoComplete="on" value={softCap} />
                                            </div>
                                            {
                                                Number(softCap) <= 0 ?
                                                    <Notic types={"error"} msg={"Softcap must be positive number"} />
                                                    :
                                                    typeof Number(softCap) !== 'number' ?
                                                        <Notic types={"error"} msg={"Softcap cannot be blank"} />
                                                        :
                                                        (
                                                            Number(softCap) > Number(hardCap) ?
                                                                <Notic types={"error"} msg={"Softcap must be less than or equal Hardcap"} />
                                                                :
                                                                (
                                                                    softCap * 100 / hardCap < 25 ?
                                                                        <Notic types={"error"} msg={"Softcap must be greater than or equal 25% of Hardcap"} />
                                                                        :
                                                                        null
                                                                )
                                                        )
                                            }
                                            <p className="help is-info">Softcap must be &gt;= 25% of Hardcap!</p>
                                        </div>
                                    </div>
                                    <div className="column pb-0">
                                        <div className="field">
                                            <label className="label" htmlFor="hardCap">HardCap ({data?.textCurrency})<sup className="has-text-danger">*</sup></label>
                                            <div className="control">
                                                <input onChange={(event) => { setHardCap(event.target.value) }} className="input" type="number" placeholder="Ex: 10" id="hardCap" name="hardCap" autoComplete="on" value={hardCap} />
                                            </div>
                                            {
                                                Number(hardCap) <= 0 ?
                                                    <Notic types={"error"} msg={"Hardcap must be positive number"} />
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label" htmlFor="minBuy">
                                                Minimum buy ({data?.textCurrency})
                                                <sup className="has-text-danger">*</sup>
                                            </label>
                                            <div className="control">
                                                <input
                                                    onChange={(e) => { setMinBuy(e.target.value) }}
                                                    className="input"
                                                    type="number"
                                                    placeholder={`0.1 ${data?.textCurrency}`}
                                                    id="minBuy"
                                                    name="minBuy"
                                                    autoComplete="on"
                                                    value={minBuy}
                                                />
                                            </div>
                                            {
                                                Number(minBuy) < 0.001 ?
                                                    <Notic types={"error"} msg={"Minimum buy must be positive number"} />
                                                    :
                                                    (
                                                        Number(minBuy) >= Number(maxBuy) ?
                                                            <Notic types={"error"} msg={"Min buy must be less than max buy"} />
                                                            : null
                                                    )
                                            }
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="field">
                                            <label className="label" htmlFor="maxBuy">
                                                Maximum buy ({data?.textCurrency})
                                                <sup className="has-text-danger">*</sup>
                                            </label>
                                            <div className="control">
                                                <input
                                                    onChange={(e) => { setMaxBuy(e.target.value) }}
                                                    className="input"
                                                    type="number"
                                                    placeholder={`0.2 ${data?.textCurrency}`}
                                                    id="maxBuy"
                                                    name="maxBuy"
                                                    autoComplete="on"
                                                    value={maxBuy}
                                                />
                                            </div>
                                            {
                                                Number(maxBuy) <= 0 ?
                                                    <Notic types={"error"} msg={"Maximum buy must be positive number"} />
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label" htmlFor="refundType">
                                                Refund type
                                            </label>
                                            <div className="control">
                                                <select onChange={(e) => { setTypeRefund(e.target.value) }} className="input" name="refundType" id="refundType">
                                                    <option selected={typeRefund == "0"} value="0">Burn</option>
                                                    <option selected={typeRefund == "1"} value="1">Refund</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        data.listingOption == "0" ?
                                            <div className="column">
                                                <div className="field">
                                                    <label className="label" htmlFor="router">
                                                        Router<sup className="has-text-danger">*</sup>
                                                    </label>
                                                    <div className="control FormField_control__EnGJA">
                                                        <select onChange={(e) => { data.router = e.target.value; fcSetData(data) }} name="router" id="router" className="input">
                                                            <option value="" disabled>---Select Router Exchange---</option>
                                                            <option value={allVarChain?.createTokenRouterExchangePancake}>Pancakeswap</option>
                                                            {/* <option value="0xcF0feBd3f17CEf5b47b0cD257aCf6025c5BFf3b7">ApeSwap</option> */}
                                                            {/* <option value="0xf400087A4c94c52C6540A325CB702DE3ee7CB37f">MDex</option> */}
                                                            {/* <option value="0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8">BiSwap</option>
                                                    <option value="0x319EF69a98c8E8aAB36Aea561Daba0Bf3D0fa3ac">PinkSwap</option>
                                                    <option value="0xC9a0F685F39d05D835c369036251ee3aEaaF3c47">Babydogeswap</option> */}
                                                        </select>
                                                        <div className="ReloadButton_wrapper__2PKUR">
                                                            <div className="ReloadButton_root__2iTCz">
                                                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M909.1 209.3l-56.4 44.1C775.8 155.1 656.2 92 521.9 92 290 92 102.3 279.5 102 511.5 101.7 743.7 289.8 932 521.9 932c181.3 0 335.8-115 394.6-276.1 1.5-4.2-.7-8.9-4.9-10.3l-56.7-19.5a8 8 0 0 0-10.1 4.8c-1.8 5-3.8 10-5.9 14.9-17.3 41-42.1 77.8-73.7 109.4A344.77 344.77 0 0 1 655.9 829c-42.3 17.9-87.4 27-133.8 27-46.5 0-91.5-9.1-133.8-27A341.5 341.5 0 0 1 279 755.2a342.16 342.16 0 0 1-73.7-109.4c-17.9-42.4-27-87.4-27-133.9s9.1-91.5 27-133.9c17.3-41 42.1-77.8 73.7-109.4 31.6-31.6 68.4-56.4 109.3-73.8 42.3-17.9 87.4-27 133.8-27 46.5 0 91.5 9.1 133.8 27a341.5 341.5 0 0 1 109.3 73.8c9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47a8 8 0 0 0 3 14.1l175.6 43c5 1.2 9.9-2.6 9.9-7.7l.8-180.9c-.1-6.6-7.8-10.3-13-6.2z"></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                                {
                                    data.listingOption == "0" ?
                                        <>
                                            <div className="columns">
                                                <div className="column">
                                                    <div className="field">
                                                        <label className="label" htmlFor="router">
                                                            Lock<sup className="has-text-danger">*</sup>
                                                        </label>
                                                        <div className="control FormField_control__EnGJA">
                                                            <select onChange={(e) => { data.lock = e.target.value; fcSetData(data) }} name="lock" id="lock" className="input">
                                                                <option value="" disabled>---Select Lock Exchange---</option>
                                                                <option value={contractLock}>3ezzyFiLock</option>
                                                                <option value={contractLockPink}>PinkLock</option>
                                                            </select>
                                                            <div className="ReloadButton_wrapper__2PKUR">
                                                                <div className="ReloadButton_root__2iTCz">
                                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M909.1 209.3l-56.4 44.1C775.8 155.1 656.2 92 521.9 92 290 92 102.3 279.5 102 511.5 101.7 743.7 289.8 932 521.9 932c181.3 0 335.8-115 394.6-276.1 1.5-4.2-.7-8.9-4.9-10.3l-56.7-19.5a8 8 0 0 0-10.1 4.8c-1.8 5-3.8 10-5.9 14.9-17.3 41-42.1 77.8-73.7 109.4A344.77 344.77 0 0 1 655.9 829c-42.3 17.9-87.4 27-133.8 27-46.5 0-91.5-9.1-133.8-27A341.5 341.5 0 0 1 279 755.2a342.16 342.16 0 0 1-73.7-109.4c-17.9-42.4-27-87.4-27-133.9s9.1-91.5 27-133.9c17.3-41 42.1-77.8 73.7-109.4 31.6-31.6 68.4-56.4 109.3-73.8 42.3-17.9 87.4-27 133.8-27 46.5 0 91.5 9.1 133.8 27a341.5 341.5 0 0 1 109.3 73.8c9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47a8 8 0 0 0 3 14.1l175.6 43c5 1.2 9.9-2.6 9.9-7.7l.8-180.9c-.1-6.6-7.8-10.3-13-6.2z"></path>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="columns">
                                                <div className="column">
                                                    <div className="field">
                                                        <label className="label" htmlFor="liquidity">
                                                            Liquidity (%)<sup className="has-text-danger">*</sup>
                                                        </label>
                                                        <div className="control">
                                                            <input
                                                                onChange={(e) => { setLiquidityRate(e.target.value) }}
                                                                className="input"
                                                                type="number"
                                                                placeholder="Ex: 51"
                                                                id="liquidity"
                                                                name="liquidity"
                                                                autoComplete="on"
                                                                value={liquidityRate}
                                                            />
                                                        </div>
                                                        {
                                                            !isInteger(liquidityRate * 1) ?
                                                                <Notic types={"error"} msg={"Liquidity must be integer"} />
                                                                :
                                                                (Number(liquidityRate) <= 50 || Number(liquidityRate) > 100 ?
                                                                    <Notic types={"error"} msg={"Liquidity must be greater than 50%"} />
                                                                    :
                                                                    null)
                                                        }
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div className="field">
                                                        <label className="label" htmlFor="listingRate">
                                                            Listing rate<sup className="has-text-danger">*</sup>
                                                        </label>
                                                        <div className="control">
                                                            <input
                                                                onChange={(e) => { setListingRate(e.target.value) }}
                                                                className="input"
                                                                type="number"
                                                                placeholder="Ex: 500"
                                                                id="listingRate"
                                                                name="listingRate"
                                                                autoComplete="on"
                                                                value={listingRate}
                                                            />
                                                        </div>
                                                        {
                                                            typeof Number(listingRate) !== "number" ?
                                                                <Notic types={"error"} msg={"Listing rate cannot be blank"} />
                                                                :
                                                                (Number(listingRate) <= 0 ?
                                                                    <Notic types={"error"} msg={"Listing rate must be positive number"} />
                                                                    :
                                                                    null)
                                                        }
                                                        <p className="help is-info">1 {data?.textCurrency} = {formatNumber(listingRate)} {data?.tokenSymbol}</p>
                                                    </div>
                                                </div>

                                            </div>

                                        </>

                                        : null
                                }
                                <div class="columns"></div>

                                <ul className="mb-4">
                                    <li>
                                        <p className="help is-info mb-0">
                                            Enter the percentage of raised funds that should be allocated to
                                            Liquidity on (Min 51%, Max 100%)
                                        </p>
                                    </li>
                                    <li>
                                        <p className="help is-info">
                                            If I spend 1 {data?.textCurrency} on how many tokens will I receive? Usually this
                                            amount is lower than presale rate to allow for a higher listing price
                                            on
                                        </p>
                                    </li>
                                </ul>

                                <div className="field">
                                    <label className="label" htmlFor="startTime">
                                        Select start time & end time (Local Time)
                                    </label>
                                    <div className="columns mb-0">
                                        <div className="column">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                                    <DateTimePicker
                                                        label="Start time (Local Time)"
                                                        value={startTime}
                                                        onChange={(newValue) => { setStartTime(roundTimeToMinute(newValue.$d)) }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                            {
                                                new Date(startTime).getTime() <= new Date().getTime() ?
                                                    <Notic types={"error"} msg={"Start time needs to be after now"} />
                                                    :
                                                    (
                                                        new Date(startTime).getTime() > new Date(endTime).getTime() ?
                                                            <Notic types={"error"} msg={"Start time needs to be before End time"} />
                                                            :
                                                            null
                                                    )
                                            }
                                        </div>
                                        <div className="column">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                                    <DateTimePicker
                                                        label="End time (Local Time)"
                                                        value={endTime}
                                                        onChange={(newValue) => { setEndTime(roundTimeToMinute(newValue.$d)) }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                            {
                                                new Date(endTime).getTime() <= new Date().getTime() ?
                                                    <Notic types={"error"} msg={"End time needs to be after Start time"} />
                                                    :
                                                    (
                                                        new Date(startTime).getTime() >= new Date(endTime).getTime() ?
                                                            <Notic types={"error"} msg={"End time needs to be before Start time"} />
                                                            :
                                                            null
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>

                                {
                                    data.listingOption == "0" ?
                                        <div className="field">
                                            <label className="label" htmlFor="liquidityLockTime">
                                                Liquidity lockup (days)<sup className="has-text-danger">*</sup>
                                            </label>
                                            <div className="control">
                                                <input
                                                    onChange={(e) => { setLiquidityLockDay(e.target.value) }}
                                                    className="input"
                                                    type="number"
                                                    placeholder="30 days"
                                                    id="liquidityLockTime"
                                                    name="liquidityLockTime"
                                                    autoComplete="on"
                                                    defaultValue="1"
                                                    value={liquidityLockDay}
                                                />
                                            </div>
                                            {
                                                typeof Number(liquidityLockDay) !== "number" ?
                                                    <Notic types={"error"} msg={"liquidityLockTime is a required field"} />
                                                    :
                                                    (Number(liquidityLockDay) < 0.001 ?
                                                        <Notic types={"error"} msg={"liquidityLockTime must be greater than or equal to 0.001"} />
                                                        :
                                                        null)
                                            }
                                        </div>
                                        : null
                                }

                                {/* <div className="field">
                            <label className="ant-checkbox-wrapper ant-checkbox-wrapper-checked">
                                <span className="ant-checkbox ant-checkbox-checked">
                                    <input type="checkbox" className="ant-checkbox-input" value="" checked />
                                    <span className="ant-checkbox-inner"></span>
                                </span>
                                <span>Using Vesting Contributor?</span>
                            </label>
                        </div> */}

                                {/* <div className="field">
                            <label for="" className="label">Vesting Contributor?</label>
                            <div className="ant-radio-group ant-radio-group-outline">
                                <div className="ant-space ant-space-horizontal ant-space-align-center" style={{ gap: 8 }}>
                                    <div className="ant-space-item">
                                        <label className="ant-radio-wrapper" onClick={() => { setVesting("false") }}>
                                            <span className={vesting == "false" ? "ant-radio ant-radio-checked" : "ant-radio"}>
                                                <input type="radio" className="ant-radio-input" value="false" />
                                                <span className="ant-radio-inner"></span>
                                            </span>
                                            <span>Disable</span>
                                        </label>
                                    </div>
                                    <div className="ant-space-item">
                                        <label className="ant-radio-wrapper" onClick={() => { setVesting("true") }}>
                                            <span className={vesting == "true" ? "ant-radio ant-radio-checked" : "ant-radio"}>
                                                <input type="radio" className="ant-radio-input" value="true" />
                                                <span className="ant-radio-inner"></span>
                                            </span>
                                            <span>Enable</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                                {
                                    vesting == "true" ?
                                        <>
                                            <div className="ant-alert ant-alert-warning ant-alert-no-icon" role="alert" style={{ marginBottom: '10px' }}>
                                                <div className="ant-alert-content">
                                                    <div className="ant-alert-message">Vesting Contributor does not support rebase tokens.</div>
                                                    <div className="ant-alert-description"></div>
                                                </div>
                                            </div>

                                            <div className="columns">
                                                <div className="column">
                                                    <div className="field">
                                                        <label className="label" htmlFor="contributorFirstTokenReleasePercent">First release for presale (percent)<sup className="has-text-danger">*</sup></label>
                                                        <div className="control">
                                                            <input className="input" type="number" placeholder="Ex: 40%" id="contributorFirstTokenReleasePercent" name="contributorFirstTokenReleasePercent" autoComplete="on" value="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="columns">
                                                <div className="column">
                                                    <div className="field">
                                                        <label className="label" htmlFor="contributorVestingPeriod">Vesting period each cycle (days)<sup className="has-text-danger">*</sup></label>
                                                        <div className="control">
                                                            <input className="input" type="number" placeholder="Enter (days). Ex: 3" id="contributorVestingPeriod" name="contributorVestingPeriod" autoComplete="on" value="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="column">
                                                    <div className="field">
                                                        <label className="label" htmlFor="contributorPercentUnlockPerPeriod">Presale token release each cycle (percent)<sup className="has-text-danger">*</sup></label>
                                                        <div className="control">
                                                            <input className="input" type="number" placeholder="Ex: 20%" id="contributorPercentUnlockPerPeriod" name="contributorPercentUnlockPerPeriod" autoComplete="on" value="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                        : null
                                }


                                <div class="has-text-centered">
                                    {
                                        data.listingOption != "0" ?
                                            (
                                                totalToken * 1 > data?.balance * 1 ?
                                                    <div class="has-text-danger p-4">Not enough balance in your wallet. Need {formatNumber(totalToken * 1)} {data?.tokenSymbol} to create launchpad. (Your balance: {formatNumber(data?.balance)} {data?.tokenSymbol}).</div>
                                                    :
                                                    <div class="has-text-info p-4">Need {formatNumber(totalToken * 1)} {data?.tokenSymbol} to create launchpad.</div>
                                            )

                                            :
                                            (
                                                data.feeOption == "0" ?
                                                    (
                                                        (totalToken * 1) > data?.balance * 1 ?
                                                            <div class="has-text-danger p-4">Not enough balance in your wallet. Need {formatNumber(totalToken * 1)} {data?.tokenSymbol} to create launchpad. (Your balance: {formatNumber(data?.balance)} {data?.tokenSymbol}).</div>
                                                            :
                                                            < div class="has-text-info p-4">Need {formatNumber(totalToken * 1)} {data?.tokenSymbol} to create launchpad.</div>
                                                    )

                                                    :
                                                    (
                                                        (totalToken * 1) > data?.balance * 1 ?
                                                            <div class="has-text-danger p-4">Not enough balance in your wallet. Need {formatNumber(totalToken * 1)} {data?.tokenSymbol} to create launchpad. (Your balance: {formatNumber(data?.balance)} {data?.tokenSymbol}).</div>
                                                            :
                                                            < div class="has-text-info p-4">Need {formatNumber(totalToken * 1)} {data?.tokenSymbol} to create launchpad.</div>
                                                    )

                                            )
                                    }
                                    <button type="button" class="ant-btn btn-main4 ant-btn-default" onClick={() => { saveAll(); nextS("1") }}>
                                    <img src="/static/media/arrow-forward.png" />  <span>Back</span>
                                      
                                    </button>
                                    <span class="mr-4"></span>
                                    {
                                        data?.listingOption == "0" ?
                                            <button type="button" class="ant-btn btn-main2 ant-btn-primary" onClick={() => { saveAll(); nextS("3") }}
                                                disabled={
                                                    !((Number(liquidityLockDay) >= 0.001) &&
                                                        (new Date(startTime).getTime() < new Date(endTime).getTime()) &&
                                                        (new Date(startTime).getTime() > new Date().getTime()) &&
                                                        (Number(listingRate)) > 0 &&
                                                        (Number(minBuy) < Number(maxBuy)) &&
                                                        (Number(minBuy)) > 0 &&
                                                        ((softCap * 100 / hardCap) >= 25) &&
                                                        ((softCap * 100 / hardCap) <= 100) &&
                                                        (Number(softCap) > 0) &&
                                                        (preRate * 1) > 0 &&
                                                        (Number(liquidityRate) >= 51)
                                                    )
                                                }>
                                                <span>Next</span>
                                                 <img src="/static/media/arrowrightW.png" />
                                            </button>
                                            :
                                            <button type="button" class="ant-btn btn-main2  ant-btn-primary" onClick={() => { saveAll(); nextS("3") }}
                                                disabled={
                                                    !(
                                                        (new Date(startTime).getTime() < new Date(endTime).getTime()) &&
                                                        (new Date(startTime).getTime() > new Date().getTime()) &&
                                                        (Number(minBuy) < Number(maxBuy)) &&
                                                        (Number(minBuy)) > 0 &&
                                                        ((softCap * 100 / hardCap) >= 25) &&
                                                        ((softCap * 100 / hardCap) <= 100) &&
                                                        (Number(softCap) > 0) &&
                                                        (preRate * 1) > 0
                                                    )
                                                }>
                                                <span>Next Step</span>

                                               <img src="/static/media/arrowrightW.png" />
                                            </button>
                                    }

                                </div>
                            </form>
                        </div>
                    </div >
                </div>
            </div>


            <div style={{ height: "16px" }}></div>


        </div >


    );
}
export default Staking;
