import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import * as d3 from 'd3';

const TokenMetrics = ({ data, projectName, totalSup }) => {
    const [selectedPart, setSelectedPart] = useState(null);
    const [dataTokenNote, setDataTokenNote] = useState([]);
    const [hiddenTokens, setHiddenTokens] = useState([]);
    const [dataToken, setDataToken] = useState([]);

    const svgRef = useRef();
    const tooltipRef = useRef();

    useEffect(() => {
        setDataTokenNote(data);
    }, [data]);

    const updateChart = useCallback(() => {
        drawChart(dataToken);
    }, [dataToken]);

    useEffect(() => {
        positionTooltip();
    }, [selectedPart]);

    useEffect(() => {
        setDataToken(dataTokenNote.filter((d) => !hiddenTokens.includes(d.title)));
    }, [dataTokenNote, hiddenTokens]);

    useEffect(() => {
        drawChart(dataToken);
    }, [dataToken]);

    // if (!data) {
    //     return
    // }
    const drawChart = useCallback((dataToken) => {
        // console.log(dataToken);
        if (dataToken.length == 0) {
            return
        }
        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove();

        let width = 400;
        let height = 400;
        let radius = Math.min(width, height) / 3;
        let centerX = width / 2;
        let centerY = height / 2;

        if (window.innerWidth < 570) {
            width = width * 0.8 - 40;
            height = height * 0.8;
            radius = radius * 0.8;
            centerX = centerX * 0.8 - 50;
            centerY = centerY * 0.8;
        }

        const pie = d3.pie().value((d) => d.value).sort(null).padAngle(0.02);

        const arc = d3.arc().innerRadius(50).outerRadius(radius - 10);

        const chartContainer = svg.append('g').attr('transform', `translate(${centerX},${centerY})`);

        const arcs = chartContainer
            .selectAll('arc')
            .data(pie(dataToken))
            .enter()
            .append('g')
            .attr('class', 'arc');

        arcs
            .append('path')
            .attr('d', arc)
            .attr('fill', (d) => d.data.color)
            .attr('cursor', 'pointer')
            .on('mouseover', (event, d) => handleMouseOver(event, d))
            .on('mouseout', () => handleMouseOut())
            .on('click', (event, d) => handleArcClick(d));

        const separatorArc = d3.arc().innerRadius(radius - 5).outerRadius(radius);
        arcs
            .append('path')
            .attr('d', separatorArc)
            .attr('fill', 'white')
            .attr('stroke', 'white')
            .attr('strokeWidth', 2);

        const legendWidth = 20;
        const legendHeight = 12;
        const legendSpacing = 10;
        const legendContainer = svg.append('g').attr('transform', `translate(${width - 50}, ${height / 2 - dataToken.length * (legendHeight + legendSpacing) / 2})`);

        const legends = legendContainer
            .selectAll('.legend')
            .data(dataTokenNote)
            .enter()
            .append('g')
            .attr('class', 'legend')
            .style('cursor', 'pointer')
            .on('click', (event, d) => showOrHideToken(d, event))
            .attr('transform', (d, i) => `translate(0, ${i * (legendHeight + legendSpacing)})`)
            .style('opacity', (d) => (hiddenTokens.includes(d.title) ? 0.3 : 1)); // Đặt opacity tùy thuộc vào hiddenTokens

        legends
            .append('rect')
            .attr('width', legendWidth)
            .attr('height', legendHeight)
            .style('fill', (d) => d.color);

        legends
            .append('text')
            .attr('x', legendWidth + 5)
            .attr('y', legendHeight / 2 + 5)
            .text((d) => `${d.title}`)
            .style('font-size', '12px')
            .style('fill', 'black');



        svg
            .append('text')
            .attr('x', centerX)
            .attr('y', centerY)
            .attr('text-anchor', 'middle')
            .text(projectName)
            .style('font-size', `${projectName.length > 10 ? '12px' : projectName.length > 6 ? '14px' : projectName.length > 4 ? '16px' : '18px'}`)
            .style('font-weight', 'bold')
            .style('fill', '#007bff');

        // Add an event listener for window resize
        window.addEventListener('resize', updateChart);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateChart);
        };
    }, [updateChart]);




    const handleMouseOver = (event, d) => {
        if (!selectedPart || d.data.title !== selectedPart.data.title) {
            const svg = d3.select(svgRef.current);
            svg.selectAll('.arc').attr('transform', (p) => (p.data.title === d.data.title ? 'scale(1.1)' : 'scale(1)'));
            setSelectedPart(d);
        }
    };

    const handleMouseOut = () => {
        if (!selectedPart) {
            const svg = d3.select(svgRef.current);
            svg.selectAll('.arc').attr('transform', '');
            setSelectedPart(null);
        }
    };

    const handleArcClick = (clickedPart) => {
        setSelectedPart((prevState) => {
            return clickedPart;
        });
    };

    const showOrHideToken = (d) => {
        setHiddenTokens((prevState) => {
            if (prevState.includes(d.title)) {
                setDataToken([...dataToken, d]);
                return prevState.filter((token) => token !== d.title);
            } else {
                let newDataToken = dataToken.filter(item => !(item.title === d.title && item.value === d.value));
                setDataToken(newDataToken);
                return [...prevState, d.title];
            }

        });
    };

    const positionTooltip = () => {
        if (selectedPart) {
            const selectedArc = d3.select(`.${selectedPart.data.title}`).node();
            if (selectedArc) {
                const { x, y, width: arcWidth } = selectedArc.getBBox();
                const chartContainer = svgRef.current.getBoundingClientRect();
                const tooltipStyle = {
                    position: 'absolute',
                    left: chartContainer.left + x + arcWidth + 10,
                    top: chartContainer.top + y + chartContainer.height / 2 - 25,
                    padding: '10px',
                    background: 'rgba(255, 255, 255, 0.9)',
                    border: '1px solid black',
                    borderRadius: '5px',
                    textAlign: 'center',
                };
                d3.select(tooltipRef.current).style(tooltipStyle);
            }
        } else {
            d3.select(tooltipRef.current).style('display', 'none');
        }
    };

    const countPercentToken = () => {
        const totalValue = dataToken.reduce((total, item) => total + item.value, 0);
        return totalValue;
    }

    return (
        <div style={{ position: 'relative' }}>
            <svg ref={svgRef} width={window.innerWidth > 570 ? 500 : "100%"} height={window.innerWidth > 570 ? 350 : 280} style={{ marginTop: -50 }}></svg>
            {selectedPart && (
                <div ref={tooltipRef} style={{ display: 'block' }}>
                    <div style={{ width: '15px', height: '15px', background: selectedPart.data.color, display: 'inline-block', marginRight: '5px' }}></div>
                    <div style={{ display: 'inline-block' }}>{selectedPart.data.title}</div>
                    <div style={{ display: 'inline-block', marginLeft: '5px' }}>{selectedPart.data.value * 100 / (totalSup * 1)}%</div>
                </div>
            )}
        </div>
    );
};

export default TokenMetrics;