import React, { Component } from "react";
import * as LINK from "./../../constants/Links";

import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Route, Switch, Link, Redirect } from "react-router-dom";

import * as Actions from "./../../actions/index";

const Index = () =>
{
  return (
    <div class="py-6 container launchpad-page">
      <div class="launchpad-title">
        <h1>Private Sales</h1>
      </div>
      <div class="ant-tabs ant-tabs-top ant-tabs-large ant-tabs-centered">
        <div role="tablist" class="ant-tabs-nav">
          <div class="ant-tabs-nav-wrap">
            <div class="ant-tabs-nav-list" style={{ transform: "translate(0px, 0px)" }}>
              <div class="ant-tabs-tab ant-tabs-tab-active">
                <div role="tab" aria-selected="true" class="ant-tabs-tab-btn" tabIndex="0" id="rc-tabs-0-tab-all" aria-controls="rc-tabs-0-panel-all">All Private Sales </div>
              </div>
              <div class="ant-tabs-tab">
                <div role="tab" aria-selected="false" class="ant-tabs-tab-btn" tabIndex="0" id="rc-tabs-0-tab-my" aria-controls="rc-tabs-0-panel-my">My Private Sale
                </div>
              </div>
              <div class="ant-tabs-tab">
                <div role="tab" aria-selected="false" class="ant-tabs-tab-btn" tabIndex="0" id="rc-tabs-0-tab-contribute" aria-controls="rc-tabs-0-panel-contribute">My Contributions</div>
              </div>
              <div class="ant-tabs-ink-bar ant-tabs-ink-bar-animated" style={{ left: "0px", width: "111px" }}></div>
            </div>
          </div>
          <div class="ant-tabs-nav-operations ant-tabs-nav-operations-hidden"><button type="button" class="ant-tabs-nav-more" tabIndex="-1" aria-hidden="true" aria-haspopup="listbox" aria-controls="rc-tabs-0-more-popup" id="rc-tabs-0-more" aria-expanded="false" style={{ visibility: "hidden", order: "1" }}><span role="img"
            aria-label="ellipsis" class="anticon anticon-ellipsis"><svg
              viewBox="64 64 896 896" focusable="false" data-icon="ellipsis"
              width="1em" height="1em" fill="currentColor" aria-hidden="true">
              <path
                d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z">
              </path>
            </svg></span></button></div>
        </div>
        <div class="ant-tabs-content-holder">
          <div class="ant-tabs-content ant-tabs-content-top">
            <div role="tabpanel" tabIndex="0" aria-hidden="false" class="ant-tabs-tabpane ant-tabs-tabpane-active" id="rc-tabs-0-panel-all" aria-labelledby="rc-tabs-0-tab-all">
              <div class="pool-filter">
                <div class="search">
                  <div class="filter-title invisible">s</div><span class="ant-input-affix-wrapper" style={{ borderRadius: "16px" }}><input
                    placeholder="Enter token name or token symbol" type="text"
                    class="ant-input" value="" /><span class="ant-input-suffix"><span
                      role="button" aria-label="close-circle" tabIndex="-1"
                      class="anticon anticon-close-circle ant-input-clear-icon-hidden ant-input-clear-icon"><svg
                        viewBox="64 64 896 896" focusable="false"
                        data-icon="close-circle" width="1em" height="1em"
                        fill="currentColor" aria-hidden="true">
                        <path
                          d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z">
                        </path>
                      </svg></span></span>
                  </span>
                </div>
                <div class="filter">
                  <div class="status">
                    <div class="filter-title">Filter By</div>
                    <div class="ant-select ant-select-single ant-select-show-arrow" style={{ width: "110px" }}>
                      <div class="ant-select-selector"><span class="ant-select-selection-search"><input
                        autoComplete="off" type="search"
                        class="ant-select-selection-search-input"
                        role="combobox" aria-haspopup="listbox"
                        aria-owns="rc_select_0_list"
                        aria-autoComplete="list"
                        aria-controls="rc_select_0_list"
                        aria-activedescendant="rc_select_0_list_0"
                        readonly="" unselectable="on" value=""
                        id="rc_select_0" style={{ opacity: "0" }} /></span><span class="ant-select-selection-item" title="All Status">All
                          Status</span></div><span class="ant-select-arrow" unselectable="on" aria-hidden="true" style={{ userSelect: "none" }}><span role="img"
                            aria-label="down"
                            class="anticon anticon-down ant-select-suffix"><svg
                              viewBox="64 64 896 896" focusable="false"
                              data-icon="down" width="1em" height="1em"
                              fill="currentColor" aria-hidden="true">
                              <path
                                d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z">
                              </path>
                            </svg></span></span>
                    </div>
                  </div>
                  <div>
                    <div class="filter-title">Sort By</div>
                    <div class="ant-select ant-select-single ant-select-show-arrow" style={{ width: "110px" }}>
                      <div class="ant-select-selector"><span class="ant-select-selection-search"><input
                        autoComplete="off" type="search"
                        class="ant-select-selection-search-input"
                        role="combobox" aria-haspopup="listbox"
                        aria-owns="rc_select_1_list"
                        aria-autoComplete="list"
                        aria-controls="rc_select_1_list"
                        aria-activedescendant="rc_select_1_list_0"
                        readonly="" unselectable="on" value=""
                        id="rc_select_1" style={{ opacity: "0" }} /></span><span class="ant-select-selection-item" title="No Filter">No
                          Filter</span></div><span class="ant-select-arrow" unselectable="on" aria-hidden="true" style={{ userSelect: "none" }}><span role="img"
                            aria-label="down"
                            class="anticon anticon-down ant-select-suffix"><svg
                              viewBox="64 64 896 896" focusable="false"
                              data-icon="down" width="1em" height="1em"
                              fill="currentColor" aria-hidden="true">
                              <path
                                d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z">
                              </path>
                            </svg></span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns is-multiline">
                <div class="PrivateSaleItem_root__4F30W">
                  <div class="lazyload-wrapper " style={{ height: "100%" }}>
                    <div class="custom-card card is-flex is-flex-direction-column" style={{ height: "100%" }}>
                      <div class="card-content is-flex-grow-1">
                        <div class="media is-flex is-align-items-center">
                          <figure class="media-left" style={{ border: "1px solid rgba(249, 81, 146, 0.2)", borderRadius: "50%", overflow: "hidden" }}>
                            <p class="image is-48x48"><img src="https://caishenkin.finance/wp-content/uploads/2022/10/msg1292313701-28304-removebg-preview.png" alt="" style={{ filter: "grayscale(0)" }} /></p>
                          </figure>
                          <div class="media-content">
                            <div class="is-flex is-justify-content-flex-end">
                              <div class="is-flex has-text-right is-flex-direction-column">
                                <div class="is-flex"><span class="is-flex status-dot canceled"><svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth="0"
                                  viewBox="0 0 16 16" height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="8" cy="8" r="8">
                                  </circle>
                                </svg><span
                                  style={{ whiteSpace: "nowrap" }}>Canceled</span></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="content-title">
                          <p class="title">CAISHENKIN.FINANCE</p>
                          <p class="subtitle">Project will receive 70% at first release </p>
                        </div>
                        <div class="soft-hard-cap">
                          <p>Soft/Hard </p><span>7.5 BNB - 15 BNB</span>
                        </div>
                        <div class="content-progress">
                          <p class="title">Progress (0.00%)</p>
                          <div class="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default" style={{ marginTop: "-4px" }}>
                            <div class="ant-progress-outer">
                              <div class="ant-progress-inner">
                                <div class="ant-progress-bg" style={{ width: "0%", height: "8px", background: "rgb(72, 199, 116)" }}>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="sub-title is-flex is-align-items-center">
                            <div class="is-flex-grow-1">0 BNB</div>
                            <div class="is-flex-grow-1 has-text-right">15 BNB
                            </div>
                          </div>
                        </div>
                        <div class="custom-card-footer">
                          <div class="is-flex is-align-items-center">
                            <div class="countdown is-flex-grow-1 is-flex-direction-column">
                              <div>Presale:</div>
                              <div>Canceled</div>
                            </div>
                            <div class="view-pool has-text-right"><a class="view-button" href="/private-sale/0xa7918DdB6Fda1871660782015CC6f3703a8aEF69/1?chain=BSC">View
                              Pool</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="PrivateSaleItem_root__4F30W">
                  <div class="lazyload-wrapper " style={{ height: "100%" }}>
                    <div class="custom-card card is-flex is-flex-direction-column" style={{ height: "100%" }}>
                      <div class="card-content is-flex-grow-1">
                        <div class="media is-flex is-align-items-center">
                          <figure class="media-left" style={{ border: "1px solid rgba(249, 81, 146, 0.2)", borderRadius: "50%", overflow: "hidden" }}>
                            <p class="image is-48x48"><img src="https://photos.pinksale.finance/file/pinksale-logo-upload/1668537060340-4849a20a0cfa9a8751702311bb936bba.png" alt="" style={{ filter: "grayscale(0)" }} /></p>
                          </figure>
                          <div class="media-content">
                            <div class="is-flex is-justify-content-flex-end">
                              <div class="is-flex has-text-right is-flex-direction-column">
                                <div class="is-flex"><span class="is-flex status-dot inprogress"><svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth="0"
                                  viewBox="0 0 16 16" height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="8" cy="8" r="8">
                                  </circle>
                                </svg><span
                                  style={{ whiteSpace: "nowrap" }}>Sale
                                    Live</span></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="content-title">
                          <p class="title">QATAR CHAMPS WORLD CUP ⚽</p>
                          <p class="subtitle">Project will receive 40% at first release </p>
                        </div>
                        <div class="soft-hard-cap">
                          <p>Soft/Hard </p><span>60 BNB - 120 BNB</span>
                        </div>
                        <div class="content-progress">
                          <p class="title">Progress (0.08%)</p>
                          <div class="ant-progress ant-progress-line ant-progress-status-active ant-progress-default" style={{ marginTop: "-4px" }}>
                            <div class="ant-progress-outer">
                              <div class="ant-progress-inner">
                                <div class="ant-progress-bg" style={{ width: "0.08%", height: "8px", background: "rgb(72, 199, 116)" }}>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="sub-title is-flex is-align-items-center">
                            <div class="is-flex-grow-1">0.1 BNB</div>
                            <div class="is-flex-grow-1 has-text-right">120 BNB
                            </div>
                          </div>
                        </div>
                        <div class="custom-card-footer">
                          <div class="is-flex is-align-items-center">
                            <div class="countdown is-flex-grow-1 is-flex-direction-column">
                              <div>Sale Ends In:</div>
                              <div class="countdown-text">
                                <strong><span>03</span><span
                                  class="dotdot">:</span><span>03</span><span
                                    class="dotdot">:</span><span>35</span><span
                                      class="dotdot">:</span><span>31</span></strong>
                              </div>
                            </div>
                            <div class="view-pool has-text-right"><a class="view-button" href="/private-sale/0x7D28EC83F3Ef64e42d624f983745f2beA64f1aB4/1?chain=BSC">View
                              Pool</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="PrivateSaleItem_root__4F30W">
                  <div class="lazyload-wrapper " style={{ height: "100%" }}>
                    <div class="custom-card card is-flex is-flex-direction-column" style={{ height: "100%" }}>
                      <div class="card-content is-flex-grow-1">
                        <div class="media is-flex is-align-items-center">
                          <figure class="media-left" style={{ border: "1px solid rgba(249, 81, 146, 0.2)", borderRadius: "50%", overflow: "hidden" }}>
                            <p class="image is-48x48"><img src="https://dinoinu.site/doxi200.png" alt="" style={{ filter: "grayscale(0)" }} /></p>
                          </figure>
                          <div class="media-content">
                            <div class="is-flex is-justify-content-flex-end">
                              <div class="is-flex has-text-right is-flex-direction-column">
                                <div class="is-flex"><a href="https://github.com/cyberscope-io/kyc/blob/main/doxi/kyc.png" class="is-flex tag is-success is-small mr-2" target="_blank" rel="noopener nofollow noreferrer">KYC</a>
                                  <span class="is-flex status-dot inprogress"><svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 16 16" height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="8" cy="8" r="8">
                                    </circle>
                                  </svg><span
                                    style={{ whiteSpace: "nowrap" }}>Sale
                                      Live</span></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="content-title">
                          <p class="title">DinoX Inu Investment</p>
                          <p class="subtitle">Project will receive 95% at first release </p>
                        </div>
                        <div class="soft-hard-cap">
                          <p>Soft/Hard </p><span>20 BNB - 40 BNB</span>
                        </div>
                        <div class="content-progress">
                          <p class="title">Progress (52.42%)</p>
                          <div class="ant-progress ant-progress-line ant-progress-status-active ant-progress-default" style={{ marginTop: "-4px" }}>
                            <div class="ant-progress-outer">
                              <div class="ant-progress-inner">
                                <div class="ant-progress-bg" style={{ width: "52.42%", height: "8px", background: "rgb(72, 199, 116)" }}>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="sub-title is-flex is-align-items-center">
                            <div class="is-flex-grow-1">20.96686442 BNB</div>
                            <div class="is-flex-grow-1 has-text-right">40 BNB
                            </div>
                          </div>
                        </div>
                        <div class="custom-card-footer">
                          <div class="is-flex is-align-items-center">
                            <div class="countdown is-flex-grow-1 is-flex-direction-column">
                              <div>Sale Ends In:</div>
                              <div class="countdown-text">
                                <strong><span>00</span><span
                                  class="dotdot">:</span><span>15</span><span
                                    class="dotdot">:</span><span>51</span><span
                                      class="dotdot">:</span><span>31</span></strong>
                              </div>
                            </div>
                            <div class="view-pool has-text-right"><a class="view-button" href="/private-sale/0x2F6058307238dAAfE632A345D238DC4dACc9b556/1?chain=BSC">View
                              Pool</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="PrivateSaleItem_root__4F30W">
                  <div class="lazyload-wrapper " style={{ height: "100%" }}>
                    <div class="lazyload-placeholder" style={{ height: "419px" }}></div>
                  </div>
                </div>
                <div class="PrivateSaleItem_root__4F30W">
                  <div class="lazyload-wrapper " style={{ height: "100%" }}>
                    <div class="lazyload-placeholder" style={{ height: "419px" }}></div>
                  </div>
                </div>
                <div class="PrivateSaleItem_root__4F30W">
                  <div class="lazyload-wrapper " style={{ height: "100%" }}>
                    <div class="lazyload-placeholder" style={{ height: "419px" }}></div>
                  </div>
                </div>
                <div class="PrivateSaleItem_root__4F30W">
                  <div class="lazyload-wrapper " style={{ height: "100%" }}>
                    <div class="lazyload-placeholder" style={{ height: "419px" }}></div>
                  </div>
                </div>
                <div class="PrivateSaleItem_root__4F30W">
                  <div class="lazyload-wrapper " style={{ height: "100%" }}>
                    <div class="lazyload-placeholder" style={{ height: "419px" }}></div>
                  </div>
                </div>
                <div class="PrivateSaleItem_root__4F30W">
                  <div class="lazyload-wrapper " style={{ height: "100%" }}>
                    <div class="lazyload-placeholder" style={{ height: "419px" }}></div>
                  </div>
                </div>
                <div class="PrivateSaleItem_root__4F30W">
                  <div class="lazyload-wrapper " style={{ height: "100%" }}>
                    <div class="lazyload-placeholder" style={{ height: "419px" }}></div>
                  </div>
                </div>
                <div class="PrivateSaleItem_root__4F30W">
                  <div class="lazyload-wrapper " style={{ height: "100%" }}>
                    <div class="lazyload-placeholder" style={{ height: "419px" }}></div>
                  </div>
                </div>
                <div class="PrivateSaleItem_root__4F30W">
                  <div class="lazyload-wrapper " style={{ height: "100%" }}>
                    <div class="lazyload-placeholder" style={{ height: "419px" }}></div>
                  </div>
                </div>
              </div>
              <div class="has-text-centered mt-2">
                <div class="ant-spin"><span class="ant-spin-dot ant-spin-dot-spin"><i
                  class="ant-spin-dot-item"></i><i
                    class="ant-spin-dot-item"></i><i
                      class="ant-spin-dot-item"></i><i
                        class="ant-spin-dot-item"></i></span></div><a href="#">View more
                          pools</a>
              </div>
            </div>
            <div role="tabpanel" tabIndex="-1" aria-hidden="true" class="ant-tabs-tabpane" id="rc-tabs-0-panel-my" aria-labelledby="rc-tabs-0-tab-my" style={{ display: "none" }}></div>
            <div role="tabpanel" tabIndex="-1" aria-hidden="true" class="ant-tabs-tabpane" id="rc-tabs-0-panel-contribute" aria-labelledby="rc-tabs-0-tab-contribute" style={{ display: "none" }}></div>
          </div>
        </div>
      </div>
    </div>

  );
}
const mapStateToProps = (state) =>
{
  return {

  };
};
const mapDispatchToProps = (dispatch, props) =>
{
  return {
    // GetConvertion: (idpage) =>
    // {
    //   dispatch(Actions.GetConvertionRequest(idpage));
    // },
    // Getprovince: () =>
    // {
    //   dispatch(Actions.GetprovinceRequest());
    // },
    // GetComment: (idpage) =>
    // {
    //   dispatch(Actions.GetCommentRequest(idpage));
    // },
    // GetPageList: (data) =>
    // {
    //   dispatch(Actions.GetPageListRequest(data));
    // },
    // SetPageLocal: (data) =>
    // {
    //   dispatch(Actions.SetPageLocal(data));
    // },
    // GetSetting: () =>
    // {
    //   dispatch(Actions.GetSettingRequest());
    // },
    // GetSuportReply: () =>
    // {
    //   dispatch(Actions.GetSuportReplyRequest());
    // },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
