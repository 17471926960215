import React, { Component } from "react";
import { connect } from "react-redux";
import * as Actions from "./../../actions/index";

import routers from "./../../routers";
import "./style.css";
import * as LINK from "./../../constants/Links";
import { Spin, message } from 'antd';
import { useState } from "react";
import { useEffect } from "react";
const Login = ({...props}) =>
{
  const [login,setLogin] = useState("");
  const [loading,setLoading] = useState("");
  const [user,setUser] = useState( {
    user: "",
    pass: ""
  });



  const SetLoading = () => setLoading(!loading);

  const OnchangeLogin = (event) =>
  {
    let target = event.target;
    let name = target.name;
    let value = target.value;
    setUser({ ...user, [name]: value })
  }

  const Login = () =>
  {
    let Login;
    if (!user.user || !user.pass) {
      alert("Please enter full information!");
      return;
    }

    if (user.user === 'admin@binanstep.com' && user.pass === 'Lkakjj@@..s1@13w') {
      SetLoading();
      Login({ data: user, code: 200 })
    }
    else {
      message.warning('The user name or password you entered is incorrected.');

    }


  }
  const OnkeyUpLogin = (event) =>
  {

    if (event.keyCode === 13) {
      Login();
    }
  }
 
  return (
    <div className="login-select-chat">
      <div className="login_overlay"></div>
      <div className="login-form-chat">
        <div className="content-login-chat">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="wrap">
                  {/* <div className="img" style={{ backgroundImage: `url(${LINK.endpoint}/images/ic_login.png)` }}></div> */}
                  <div className="login-wrap">
                    <form action="#" className="signin-form">
                      <div className="form-group mt-4">
                        <label className="form-control-placeholder" htmlFor="username">User Name</label>
                        <input type="text" name="user" className="form-control" required value={user.user} onChange={OnchangeLogin} onKeyUp={OnkeyUpLogin} />
                      </div>
                      <div className="form-group mt-4">
                        <label className="form-control-placeholder" htmlFor="password">PassWord</label>
                        <input id="password" type="password" name="pass" className="form-control" required value={user.pass} onChange={OnchangeLogin} onKeyUp={OnkeyUpLogin} />
                      </div>
                      <div className="form-group">
                        <button type="button" className="form-control btn btn-primary rounded submit px-3" onClick={Login} >Login</button>

                      </div>
                      {loading && <div className="form-group">
                        <Spin />
                      </div>}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) =>
{
  return {
    login: state.Login
  };
};
const mapDispatchToProps = (dispatch, props) =>
{
  return {
    Login: (data) =>
    {
      dispatch(Actions.LoginRequest(data));
    },
    ResetLogin: () =>
    {
      dispatch(Actions.ResetLogin());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
