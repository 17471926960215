import HeaderV2 from "components/Header/HeaderV2";
import SidebarV2 from "components/Sidebar/SidebarV2";
import Layout from "components/layout/Layout";
import React from "react";

function IbLevel() {
  return (
    <Layout>
      <div
        style={{ backgroundColor: "#0D102D" }}
        className="body customers_page flex-grow-1 px-3"
      >
        <div className="body customers_page flex-grow-1 px-3">
          <div className="container-lg ibgate-page">
            <h1>IB</h1>
            <div className="body genealogy-body genealogy-scroll">
              <div className="genealogy-tree">
                <pre></pre>
                <ul>
                  <li>
                    <a className="fatherTOP" href="javascript:void(0);">
                      <div className="member-view-box">
                        <div className="member-image">
                          <img
                            src="images/bff/person.png"
                            alt="Member"
                          />
                          <div className="member-details">
                            <h3>iswap636 - [DIUPJMAY]</h3>
                          </div>
                        </div>
                      </div>
                    </a>

                    <ul className="active">
                      <li>
                        <span className="leftRight left_branch">Left</span>{" "}
                        <a
                          data-level="1"
                          className="highlight"
                          id="getChile_3026"
                          href="javascript:void(0);"
                        >
                          <div className="member-view-box">
                            <div className="member-image">
                              <img
                                src="images/bff/person.png"
                                alt="Member"
                              />
                              <div className="member-details">
                                <h3>Mei Mei- [NYE1VLKR]</h3>
                              </div>
                            </div>
                            <span className="volume highlight">
                              Lending <strong>100 BFF</strong>
                            </span>
                          </div>
                          <span className="level">Lv: 1</span>
                          <div className="action-child">
                            <span
                              className="plus"
                              onclick="getChild('#getChile_', 3026, 3025)"
                            >
                              +
                            </span>
                            <span
                              className="minus hidden"
                              onclick="rmChild('#getChile_', 3026, 3025)"
                            >
                              -
                            </span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <span className="leftRight right_branch">Right</span>{" "}
                        <a
                          data-level="1"
                          className=""
                          id="getChile_3031"
                          href="javascript:void(0);"
                        >
                          <div className="member-view-box">
                            <div className="member-image">
                              <img
                                src="images/bff/person.png"
                                alt="Member"
                              />
                              <div className="member-details">
                                <h3>Phan Diep Hong Ngan- [CQY1SDVR]</h3>
                              </div>
                            </div>
                            <span className="volume ">No transactions yet</span>
                          </div>
                          <span className="level">Lv: 1</span>
                          <div className="action-child">
                            <span
                              className="plus"
                              onclick="getChild('#getChile_', 3031, 3025)"
                            >
                              +
                            </span>
                            <span
                              className="minus hidden"
                              onclick="rmChild('#getChile_', 3031, 3025)"
                            >
                              -
                            </span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default IbLevel;
