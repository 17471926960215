export const endpoint = "";
export const HOME = `${endpoint}`;
export const LAUNCHPAD = `${endpoint}/launchpad/create`;
export const LAUNCHPAD_CREATE_FAIR = `${endpoint}/fairlaunch/create`;
export const LAUNCHPAD_CREATE_SUB_SCIPTION_POOL = `${endpoint}/launchpad-create-subscription-pool`;

export const LAUNCHPAD_CREATE_TOKEN = `${endpoint}/launchpad/token/create`;
export const LAUNCHPAD_CREATE_DUTCH_AUTION = `${endpoint}/launchpad-create-dutch-aution`;
export const LAUNCHPAD_LIST = `${endpoint}/launchpad/all`;
export const LAUNCHPAD_GC = `${endpoint}/launchpad/:id`;
export const PRIVATESALE = `${endpoint}/private-sale`;
export const PRIVATESALE_LIST = `${endpoint}/privatesale-list`;
export const BUY_CRYPTO = `${endpoint}/buy-crypto`;
export const BRIDGE = `${endpoint}/bridge`;
export const INVESTORS_FUNDS = `${endpoint}/investors-funds`;

export const PINKLOCK = `${endpoint}/banklock`;
export const PINKLOCK_TOKEN = `${endpoint}/banklock-token`;
export const PINKLOCK_LIST = `${endpoint}/banklock-list`;
export const PINKLOCK_DETAIL = `${endpoint}/banklock-detail`;
export const PINKLOCK_RECORD = `${endpoint}/banklock-record`;
export const AIRDROP = `${endpoint}/airdrop`;
export const AIRDROP_LIST = `${endpoint}/airdrop-list`;
export const LEADER_BOARD = `${endpoint}/leaderboard`;
export const ANTIBOT = `${endpoint}/antibot`;
export const MULTI_SENDER = `${endpoint}/multi-sender`;
export const BANK_LOAN = `${endpoint}/bank-loan`;
export const LOGIN = `${endpoint}/LOGIN`;

